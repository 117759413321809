import { UserControllerService, DataService } from '@services';
import { Injectable } from '@angular/core';
import { Router,  CanActivate } from '@angular/router';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(
    private userService: UserControllerService,
    private router: Router,
    private dataService: DataService
  ) { }

  canActivate() {
    // User not logged In
    if (!this.userService.loggedIn()) {
      this.dataService.product.subscribe(defaultProduct => {
        if (defaultProduct) {
          this.router.navigate([`/e-commerce/payment/${defaultProduct}/1/newUser`]);
        }
      });
      return false;
    }
    return true;
  }
}
