interface Number {
  /**
   * Convert a millisecond `number` into a minute `number`.
   *
   * **Exemple**
   *
   * ```ts
   * const hourInMilliseconds = 3600000;
   *
   * console.log(hourInMilliseconds.millisecondsToMinutes()); // output: 60
   * ```
   */
  millisecondsToMinutes: () => number;
}

Number.prototype.millisecondsToMinutes = function() {
  return this / 60000;
}