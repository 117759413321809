import { Router } from '@angular/router';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'bf-titled-card',
  templateUrl: './titled-card.component.html',
  styleUrls: ['./titled-card.component.scss']
})
export class TitledCardComponent {
  @Input() backgroundImage: string;
  @Input() title: string;
  @Input() gradient = true;
  @Input() ref = '';
  @Input() componentId; // for testing automation only
  @Input() routeUrl: string;
  constructor(private router: Router) { }

  public cardClicked() {
    if (this.ref) {
      this.router.navigate([this.ref]);
    }
  }

}
