import { Injectable } from '@angular/core';

import { environment } from '@env/environment';
import { ConfigurationService } from '@services/configuration.service';
import { DataService } from '@services/data.service';

import { AuthEndpointService } from '../endpoints/auth-endpoint.service';
import { PersistenceControllerService } from './persistence-controller.service';
import { UserControllerService } from './user-controller.service';


@Injectable()
export class AuthControllerService {

  constructor(
    private authEndpoint: AuthEndpointService,
    private persistence: PersistenceControllerService,
    private dataService: DataService,
    private configurationService: ConfigurationService,
    private userControllerService: UserControllerService,
  ) { }

  // Autentica aplicação no backend do BTFIT
  auth(): void {
    if (!this.persistence.getText('appToken')) {
      this.authEndpoint.logout()
      this.persistence.saveText('appToken', environment.generalAppToken);
      this.dataService.updatedDataSelection(environment.generalAppToken);
      this.getEcommerceDefaultProduct();
    } else {
      this.dataService.updatedDataSelection(this.persistence.getText('appToken'));
      this.getEcommerceDefaultProduct();
    }
  }

  private getEcommerceDefaultProduct() {
    this.configurationService.getConfigurationByMainKey('ECOMMERCE_DEFAULT_PRODUCT').subscribe(res => {
      if (res) {
        this.dataService.updateDefaultProduct(res.defaultProduct);
      } else {
        this.dataService.updateDefaultProduct('E-PersonalTrainerOnLineAnualNDias_v2');
      }
    }, () => {
      this.dataService.updateDefaultProduct('E-PersonalTrainerOnLineAnualNDias_v2');
    });
  }
}
