<div class="screen">
  <div [@popOverState]="stateNameEnlarge">
    <div class="confetti-container">
      <canvas id="my-canvas"></canvas>
      <div class="conteudo-container">
        <img [src]="badge" alt="">
        <div class="motivational-text">
          <h1 class="TitilliumWeb-Bold">Parabéns!</h1>
          <p class="TitilliumWeb-Regular"> Você concluiu o desafio <span
              class="TitilliumWeb-Bold">{{ programName }}</span>!</p>
        </div>
        <div class="pdt-info">
          <div class="pdt-info-item">
            <fa-icon style="font-size: 42px; margin-right: 10px;" [icon]="icons[0]"></fa-icon>
            <div style="text-align: start;">
              <div class="topic-title TitilliumWeb-Regular">Tempo Total</div>
              <div class="topic-value TitilliumWeb-Bold"><strong>{{ durationText }}</strong></div>
            </div>
          </div>
          <div class="pdt-info-item">
            <fa-icon style="font-size: 42px; margin-right: 10px;" [icon]="icons[1]"></fa-icon>
            <div style="text-align: start;">
              <div class="topic-title TitilliumWeb-Regular">Gasto Total</div>
              <div class="topic-value TitilliumWeb-Bold"><strong>{{ calories }} kcal</strong></div>
            </div>
          </div>
        </div>
        <div style="margin-top: 50px;display: flex; flex-flow: column">
            <button type="button" class="btn btn-default btn-submit btn--large TitilliumWeb-Bold"
              (click)="exitCongratulationsScreen(false)">PROSSEGUIR</button>
            <button type="button" style="margin-top: 15px" class="btn btn-default btn-submit btn--large TitilliumWeb-Bold"
              (click)="exitCongratulationsScreen(true)">VER OUTROS PROGRAMAS</button>
        </div>
        <div class="return-button"></div>
      </div>
    </div>
  </div>
</div>