import { Component } from '@angular/core';
import { EndpointsService } from '@services';

@Component({
  selector: 'bf-store-buttons',
  templateUrl: 'bf-store-buttons.component.html'
})
export class StoreButtonsComponent {
  public apple: string;
  public google: string;
  private storeUrls: any;

  constructor(private endpointsService: EndpointsService) {
    this.storeUrls = this.endpointsService.getStoreUrls();
    this.endpointsService.itemSoldObs.subscribe(sold => {
      this.apple = sold ? this.storeUrls.appleSold : this.storeUrls.apple;
      this.google = sold ? this.storeUrls.googleSold : this.storeUrls.google;
    });
  }
}
