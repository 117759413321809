<a href="#" >
  <span>{{title}}</span>
  <ul class="dropdown-child" [style.width.px]="dropdownWidth">
    <li *ngFor="let item of items">
      <a href="item.link">
        <span>
          {{item.content}}
        </span>
      </a>
    </li>
  </ul>
</a>