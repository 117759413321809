import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

import { LanguageService } from '@services';
import { Plan } from '@models';

@Component({
  selector: 'bf-plan-button',
  templateUrl: './plan-button.component.html',
  styleUrls: ['./plan-button.component.scss']
})
export class PlanButtonComponent {
  @Input() plan: Plan;
  @Input() currency: string;
  @Input() alternativeCurrency: string;
  @Input() currencyRatio: number;
  public isSetToPt = this.languageService.isSetToPt();

  constructor(
    private router: Router,
    private languageService: LanguageService
  ) { }

  buyPlan() {
    this.router.navigate([`/e-commerce/payment/${this.plan.code}/1/purchase`]);
  }
}

