import { Directive, ElementRef, forwardRef, HostListener, Input, OnInit, Renderer2 } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Directive({
  // eslint-disable-next-line
  selector: '[phoneMask]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      // eslint-disable-next-line @angular-eslint/no-forward-ref
      useExisting: forwardRef(() => PhoneMaskDirective),
      multi: true
    },
  ],
})
export class PhoneMaskDirective implements OnInit, ControlValueAccessor {
  private _maskEnabled = true;
  private _maxLength = 11;

  constructor(
    private _elementRef: ElementRef,
    private _renderer: Renderer2,
  ) { }

  @Input('phoneMask')
  public set maskEnabled(value: boolean) {
    this._maskEnabled = value;
    if (this._maskEnabled) {
      this.valueChanged(this._elementRef.nativeElement);
    } else {
      this._elementRef.nativeElement.value = this.removeMask(this._elementRef.nativeElement.value)[0];
    }
  }

  private onChange: Function = (str: any) => {};
  private onTouch: Function = () => {};

  @HostListener('input', ['$event'])
  public onInput(e: KeyboardEvent): void {
    let newValue: string;
    let shift: number;
    const el: HTMLInputElement = (e.target as HTMLInputElement);
    let position: number = el.selectionStart;

    if (this._maskEnabled) {
      [newValue, shift] = this.valueChanged(this._elementRef.nativeElement, position);
      if ((e as any).inputType === 'deleteContentBackward') {
        if (position > 0 && newValue[position - 1] === '-') {
          shift = -1;
        } else if (position > 0 && newValue[position] === '-') {
          newValue = newValue.slice(0, position - 1) + newValue.slice(position);
          this._elementRef.nativeElement.value = newValue;
          position = position - 1;
          [newValue, shift] = this.valueChanged(this._elementRef.nativeElement, position);
        } else {
          shift = 0;
        }
      }
    } else {
      [newValue, shift] = this.removeMask(this._elementRef.nativeElement.value, position);
      this._elementRef.nativeElement.value = newValue;
    }

    el.selectionStart = el.selectionEnd = position + shift;

  }

  public writeValue(inputValue: any): void {
    if (!inputValue) {
      return;
    }

    if (this._maskEnabled) {
      this._elementRef.nativeElement.value = this.applyMask(inputValue);
    } else {
      this._elementRef.nativeElement.value = inputValue;
    }
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      return this._renderer.setAttribute(this._elementRef.nativeElement, 'disabled', 'true');
    }
    return this._renderer.removeAttribute(this._elementRef.nativeElement, 'disabled');
  }

  public ngOnInit(): void {
  }


  private valueChanged(element: HTMLInputElement, position = 0): [string, number] {
    const val: string = element.value;
    const [masked, shift] = this.applyMask(val, position);
    element.value = masked;

    this.onChange(this.removeMask(masked)[0]);
    return [masked, shift];
  }

  private applyMask(inputStr: string, position = 0): [string, number] {
    let out: string;
    let shift: number;

    [out, shift] = this.removeMask(inputStr, position);

    out = out.slice(0, this._maxLength);

    let cut;
    if (out.length === this._maxLength) {
      cut = 10;
    } else if (out.length > 7) {
      cut = 11;
    }

    if (out.length >= 3) {
      out = '(' + out.slice(0, 2) + ') ' + out.slice(2);
      shift += 3;
    }

    if (cut) {
      out = out.slice(0, cut) + '-' + out.slice(cut);
      if (position >= cut) {
        shift += 1;
      }
    }
    return [out, shift];
  }

  private removeMask(value: string, position = 0): [string, number] {
    if (!value) {
      return [value, 0];
    }
    const removeExp = /[^\d]/g;
    let start: string = value.slice(0, position);
    let end: string = value.slice(position);
    const previousLength: number = start.length;
    start = start.replace(removeExp, '');
    end = end.replace(removeExp, '');

    return [start + end, start.length - previousLength];
  }

}
