import { Component, Input } from '@angular/core';

@Component({
  selector: 'bf-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent {

  @Input() text = 'Carregando...';
  @Input() loaderSize = 60;
  @Input() borderColor = 'white';
  public Math: Math = Math;

}
