import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  CustomerProduct,
  CustomerProductDetail,
  Plan,
  Product,
  Status,
  StoreType,
  TrainingProgram,
  TrainingProgramBasicInfo,
} from '@models';
import { EndpointsService } from '@services/endpoints.service';


@Injectable({
  providedIn: 'root'
})
export class ProductsService {

  constructor(
    private endpointsService: EndpointsService,
    private http: HttpClient
  ) { }

  getCustomerProducts(): Observable<CustomerProductDetail> {
    return this.http.get(this.endpointsService.get('products.customerProducts'))
      .pipe(map(obj => obj as CustomerProductDetail));
  }

  getByProductId(id): Observable<Array<Product>> {
    return this.http.get(this.endpointsService.get('products.byProductId', [id]))
      .pipe(map(obj => obj['products'] as Array<Product>));
  }

  getPlans(productCodes: Array<string>): Observable<Array<Plan>> {
    return this.http.post(this.endpointsService.get('products.retrieve'), { productCodes })
      .pipe(
        map(obj => obj['storePlans'] as Array<Plan>),
        map((plans: Array<Plan>) => plans.map(plan => this.planMapper(plan)))
      );
  }

  getPlan(code: string): Observable<Plan> {
    return this.http.get<Plan>(this.endpointsService.get('products.retrieveById') + code)
      .pipe(map((plan: Plan) => this.planMapper(plan)));
  }

  getPlanWithoutLogin(code: string): Observable<Plan> {
    return this.http.get<Plan>(this.endpointsService.get('products.retrieveByIdWithoutLogin') + code)
      .pipe(map((plan: Plan) => this.planMapper(plan)));
  }

  public planMapper(plan: Plan): Plan {
    switch (plan.planType) {
      default:
      case 1: // Monthly
        plan.installments = 1;
        break;
      case 2: // Annual
        plan.installments = 12;
        break;
      case 4: // Semestral
        plan.installments = 6;
        break;
    }
    plan.monthlyPrice = (plan.price / 100) / plan.installments;
    plan.firstCharge = new Date(Date.now() + 604800000);
    return plan;
  }

  getProductsByUser(): Observable<{ plans: Plan[], trainingPrograms: TrainingProgram[] }> {
    return this.http.get(this.endpointsService.get('products.getByUser'))
      .pipe(map(obj => obj as { plans: Plan[], trainingPrograms: TrainingProgram[] }));
  }

  cancelSubscription(merchantOrderId: number): Observable<any> {
    return this.http.post(this.endpointsService.get('purchase.cancelRecurrentPurchase'), { merchantOrderId: merchantOrderId });
  }

  // retorna os planos comprados pelo usuário no ecommerce e no app do btfit
  getUserPlans() {
    return forkJoin([
      this.http.get<{ plans: Plan[] }>(this.endpointsService.get('products.getByUser')),
      this.http.get<CustomerProductDetail>(this.endpointsService.get('products.customerProducts'))
    ]).pipe(
      map(([{ plans: eCommercePlans }, { CustomerProducts }]) => {
        const storePlans = CustomerProducts.filter(elem => elem.Type === 1);
        return { eCommercePlans, storePlans };
      })
    );
  }

  // retorna os programas de treinamento comprados pelo usuário no ecommerce e no app do btfit
  getUserTrainingPrograms() {
    return forkJoin([
      this.http.get<{ trainingPrograms: TrainingProgramBasicInfo[] }>(this.endpointsService.get('products.getByUser')),
      this.http.get<CustomerProductDetail>(this.endpointsService.get('products.customerProducts'))
    ]).pipe(
      map(([{ trainingPrograms: eCommerceTrainingPrograms }, { CustomerProducts }]) => {
        const storeTrainingPrograms = CustomerProducts.filter(elem => elem.Type === 2);
        return { eCommerceTrainingPrograms, storeTrainingPrograms };
      })
    );
  }

  // retorna os programas de treinamento e planos comprados pelo usuário no ecommerce e no app do btfit
  getUserProducts() {
    return forkJoin([
      this.http.get<{ trainingPrograms: TrainingProgram[], plans: Plan[] }>(this.endpointsService.get('products.getByUser')),
      this.http.get<CustomerProductDetail>(this.endpointsService.get('products.customerProducts'))
    ]).pipe(
      map(([{ trainingPrograms: eCommerceTrainingPrograms, plans: eCommercePlans }, { CustomerProducts }]) => {
        const storePlans = CustomerProducts.filter(elem => elem.Type === 1);
        const storeTrainingPrograms = CustomerProducts.filter(elem => elem.Type === 2);
        return { eCommerceTrainingPrograms, eCommercePlans, storePlans, storeTrainingPrograms };
      })
    );
  }

  // retorna se o usuário possui plano premium ou não
  hasPlan() {
    return this.getUserPlans().pipe(
      map(({ storePlans, eCommercePlans }) => {
        return (
          storePlans.find(
            (elem) =>
              Number(elem.Status) === 1 ||
              Number(elem.Status) === 2 ||
              Number(elem.Status) === 12
          ) || eCommercePlans.find((item) => item.active)
        );
      })
    );
  }

  // retorna se o usuário possui um determinado pdt ou não
  hasTrainingProgram(code: string) {
    return this.getUserTrainingPrograms().pipe(
      map(({ eCommerceTrainingPrograms, storeTrainingPrograms }) => {
        eCommerceTrainingPrograms = eCommerceTrainingPrograms.filter(elem => (elem.status === 1 || elem.status === 2));
        return Boolean(eCommerceTrainingPrograms.find(item => item.productCode === code && item.active)) ||
          Boolean(storeTrainingPrograms.find(_item => _item.StoreProductCode && _item.StoreProductCode === code));
      })
    );
  }


  public getSubscriptionInformation() {
    return this.http.get<any>(this.endpointsService.get('customer.subscriptionInformation'))
  }

  public getProducts() {
    return forkJoin([this.getCustomerProducts(), this.getProductsByUser()])
      .pipe(
        map(responses => {
          const appProducts = responses[0].CustomerProducts.filter(_product => _product.StoreType !== StoreType.Ecommerce);
          const ecmProducts = responses[1];
          const premiumEcm = ecmProducts.plans;
          const premiumApp = responses[0].CustomerProducts.find(p => p.Type === 1);

          let partner = null;
          const coupon = responses[0].Coupon;
          if (responses[0].Partners && responses[0].Partners.length) {
            partner = responses[0].Partners[0];
          }

          const pdtProducts = this.handleTrainingPrograms(ecmProducts.trainingPrograms, appProducts.filter(_product => _product.Type === 2));
          const premiumProduct = this.handlePremiumProduct(premiumEcm, premiumApp);
          let showNextRecurrencyDate = false;

          if (premiumProduct && premiumProduct.cancellationDate) {
            if (premiumProduct.nextRecurrency) {
              const splittednextRecurrencyDate = premiumProduct.nextRecurrency.split('/');
              const nextRecurrencyDate = new Date(+splittednextRecurrencyDate[2], (+splittednextRecurrencyDate[1]) - 1, +splittednextRecurrencyDate[0]);
              showNextRecurrencyDate = nextRecurrencyDate > new Date();
            }
          }
          return { pdtProducts, premiumProduct, showNextRecurrencyDate, partner, coupon }
        })
      );
  }

  // prefer values from the e-commerce
  private handlePremiumProduct(ecmProducts: Plan[], appProduct: CustomerProduct): any {
    if (!ecmProducts && !appProduct) {
      return null;
    }

    let result;

    if (ecmProducts && ecmProducts.length) {
      const ecmProduct = ecmProducts[0];
      result = {
        appProduct: false,
        id: ecmProduct.id,
        merchantOrderId: ecmProduct.merchantOrderId,
        inTrialPeriod: ecmProduct.inTrialPeriod,
        active: ecmProduct.active,
        code: ecmProduct.code,
        daysOfTrial: ecmProduct.daysOfTrial,
        nextRecurrency: ecmProduct.nextRecurrency,
        discountedRecurrences: ecmProduct.discountedRecurrences,
        introdutoryPercentageDiscount: ecmProduct.introdutoryPercentageDiscount,
        planType: ecmProduct.planType,
        currency: ecmProduct.currency || 'BRL',
        price: ecmProduct.price,
        status: ecmProduct.status,
        installments: ecmProduct.installments,
        storeType: appProduct ? appProduct.StoreType : StoreType.Ecommerce,
        cancellationDate: ecmProduct.cancellationDate,
        appProductStatus: appProduct ? appProduct.Status : null,
        appProductNextBillingDateTicksMobile: appProduct ? appProduct.NextBillingDateTicksMobile : null
      };
    } else if (appProduct) {
      result = {
        appProduct: true,
        id: appProduct.ProductId,
        merchantOrderId: null,
        code: appProduct.StoreProductCode,
        daysOfTrial: appProduct.DaysOfTrial,
        nextRecurrency: null,
        discountedRecurrences: null,
        introdutoryPercentageDiscount: null,
        planType: appProduct.PlanType,
        currency: 'BRL',
        price: null,
        installments: null,
        storeType: appProduct.StoreType,
      };

      if (appProduct.Status === Status.Canceled) {
        return null;
      }
    }
    return result;
  }

  private handleTrainingPrograms(ecmProducts: TrainingProgram[], appProducts: CustomerProduct[]): any[] {
    const result = [];

    if (!ecmProducts && !appProducts) {
      return result;
    }

    result.push(...ecmProducts);

    appProducts.forEach(_item => {
      if (!result.find(res => res.code === _item.StoreProductCode)) {
        result.push({
          id: _item.ProductId,
          code: _item.StoreProductCode,
          name: _item.ProductName,
          type: _item.StoreType
        });
      }
    });

    return result;
  }
}
