import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';

import { forkJoin, Observable } from 'rxjs';

import { EndpointsService } from '@services/endpoints.service';


@Injectable({
  providedIn: 'root'
})
export class ChallengesService implements Resolve<any> {

  constructor(private http: HttpClient, private endpointsService: EndpointsService) { }

  // challenge services
  simpleListChallengesAndPdt(): Observable<any> {
    return this.http.get(this.endpointsService.get('challengeschallengesOrPdt.simpleList'))
    // .pipe(map(obj => obj['challenges'] as Array<any>)); // to do - tipagem
  }

  challengesRanking(): Observable<any> {
    return this.http.get(this.endpointsService.get('challenges.ranking'))
    // .pipe(map(obj => obj['challenges'] as Array<any>)); // to do - tipagem
  }

  listChallengesOrPdt(callType: string): Observable<any> {
    return this.http.get(this.endpointsService.get('challengesOrPdt.list', [callType]))
    // .pipe(map(obj => obj['challenges'] as Array<any>)); // to do - tipagem
  }

  getChallengeOrPdtDetailById(callType: string, challengeId: string): Observable<any> {
    return this.http.get(this.endpointsService.get('challengesOrPdt.detail', [callType, challengeId]))
  }

  getChallengeOrPdtPlaylistById(callType: string, challengeId: string): Observable<any> {
    return this.http.get(this.endpointsService.get('challengesOrPdt.detail.playlist', [callType, challengeId]))
  }

  subscribeChallenge(callType: string, challengeId: string): Observable<any> {
    return this.http.post(this.endpointsService.get('challengesOrPdt.detail.subscribe', [callType, challengeId]), {})
  }

  itemCheckin(callType: string, challengeId: string, playlistId: string, reference_id: string, type: string, day: number): Observable<any> {
    if (callType === 'challenges') {
      return this.http.post(this.endpointsService.get('challengesOrPdt.detail.checkin', [callType, challengeId, 'checkin']), { reference_id, type, day })
    } else {
      return this.http.put(this.endpointsService.get('challengesOrPdt.detail.checkin', [callType, challengeId, playlistId]), {})
    }
  }

  // comments services
  listComments(callType: string, challengeId: string, timestamp: number): Observable<any> {
    const endpoint = callType === 'challenges' ? 'commentChallenges' : 'commentPCC';
    return this.http.get(this.endpointsService.get(endpoint + '.list', [callType, challengeId, timestamp]))
  }

  likeComment(callType: string, challengeId: string, date: number, user_id: number): Observable<any> {
    const endpoint = callType === 'challenges' ? 'commentChallenges' : 'commentPCC';
    return this.http.put(this.endpointsService.get(endpoint + '.like', [callType, challengeId]), {date, user_id});
  }

  makeComment(callType: string, challengeId: string, comment: any): Observable<any> {
    const endpoint = callType === 'challenges' ? 'commentChallenges' : 'commentPCC';
    return this.http.post(this.endpointsService.get(endpoint + '.new', [callType, challengeId]), comment);
  }

  replyComment(callType: string, challengeId: string, comment: any): Observable<any> {
    const endpoint = callType === 'challenges' ? 'commentChallenges' : 'commentPCC';
    return this.http.post(this.endpointsService.get(endpoint + '.reply', [callType, challengeId]), comment);
  }

  reportComment(callType: string, challengeId: string, date: number, user_id: number): Observable<any> {
    const endpoint = callType === 'challenges' ? 'commentChallenges' : 'commentPCC';
    return this.http.put(this.endpointsService.get(endpoint + '.report', [callType, challengeId]), {date, user_id});
  }

  resolve(route: ActivatedRouteSnapshot) {
    // return null;
    const challengeId = route.params.id;
    const callType = route.params.callType;
    return forkJoin(
        // this.getChallengeDetailById(challengeId),
        this.getChallengeOrPdtPlaylistById(callType, challengeId),
        // this.getComment()
    )
  }
}
