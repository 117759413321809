import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { EndpointsService } from '@services/endpoints.service';

import { IFeedbackBody, IFeedbackResponse } from 'app/shared/interfaces/feedback.interfaces';


@Injectable()
export class ActivityEndpointService {

    constructor(
      private http: HttpClient,
      private endpointsService: EndpointsService
    ) { }

    getActivities() {
      return this.http.get(this.endpointsService.get('activity.all'));
    }

    feedback(feedback: IFeedbackBody): Observable<IFeedbackResponse> {
      return this.http.put<IFeedbackResponse>(this.endpointsService.get('activity.feedback'), feedback);
    }
}
