import { Component, Input, HostBinding, HostListener } from '@angular/core';

@Component({
  selector: 'bf-dropdown',
  templateUrl: 'bf-dropdown.component.html',
  styleUrls: ['./bf-dropdown.component.scss']
})
export class DropdownComponent {
  @Input() title: string
  @Input() items: any[]
  @Input() dropdownWidth = 70
  @HostBinding('class.active') public languageToggle = false;

  @HostListener('click')
  toggleLanguageDropdown() {
    this.languageToggle = !this.languageToggle
  }

  onClickedOutside() {
    this.languageToggle = false
  }

}
