<div class="container background">
  <div class="row">
    <div class="">
      <div class="panel panel-login">
        <div class="panel-body">
          <div class="row">
          <div class="texto">
            <h1 i18n="@@registerNewPass">
              CADASTRO DE NOVA SENHA
            </h1>
            <p i18n="@@provideYourPassword">
              Informe uma nova senha para acessar o site.
            </p>
          </div>
          <div class="col-lg-12">
            <form
              [formGroup]="newPassForm"
              (ngSubmit)="send(newPassForm.value)"
              autocomplete="off"
              style="display: block;">
              <div class="form-group">
              <label for="password" i18n="@@passwordInput">Senha</label>
              <input type="password"
                formControlName="password"
                id="password"
                tabindex="4"
                class="form-control password"
                i18n-placeholder="@@passwordPlaceholder" placeholder="Insira sua senha...">
              <div *ngIf="password.errors && (password.dirty || password.touched)">
                <em class="required" *ngIf="password.errors.required" i18n="@@requiredInput">Campo obrigatório</em>
                <em class="required" *ngIf="password.errors.pattern"
                  i18n="@@passwordValidation">A senha deve conter de 6 a 12 caracteres e possuir letras e números.</em>
              </div>
            </div>
            <div class="form-group">
              <label for="password" i18n="@@passConfirmationInput">Confirmação de senha</label>
              <input type="password"
                formControlName="confirmPassword"
                id="confirmPassword"
                tabindex="4"
                class="form-control password"
                i18n-placeholder="@@passConfirmationPlaceholder" placeholder="Confirme sua senha...">
              <div *ngIf="confirmPassword.errors && (confirmPassword.dirty || confirmPassword.touched)">
                <em class="required" *ngIf="confirmPassword.errors.required" i18n="@@requiredInput">Campo obrigatório</em>
                <em class="required" *ngIf="confirmPassword.errors.MatchPassword" i18n="@@passwordMatch">Senhas não conferem</em>
              </div>
            </div>
                <div class="form-group">
                  <div class="row">
                    <div class="col-sm-6 col-sm-offset-3">
                        <button id="forgot-send" [ngStyle]="{'margin-top': '15px'}" [disabled]="newPassForm.invalid || loading"
                          type="submit"
                          class="form-control btn btn-default btn-submit">
                            <span *ngIf="!loading" i18n="@@sendButton">Enviar</span>
                            <i *ngIf="loading" class="fa fa-refresh white fa-spin"></i>
                        </button>
                    </div>
                  </div>
                </div>
            </form>
          </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
