import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';



import { CouponService, MapErrorService } from '@services';
import { ModalService } from '@services/modal.service';




@Component({
  selector: 'bf-coupon-modal',
  templateUrl: './coupon-modal.component.html',
  styleUrls: ['./coupon-modal.component.scss']
})

export class CouponModalComponent implements OnInit {
  @ViewChild('addCouponModal') addCouponModal: TemplateRef<any>;
  @ViewChild('removeCouponModal') removeCouponModal: TemplateRef<any>;

  @Output() couponEmitter = new EventEmitter<any>();

  public error: boolean;

  @Input() coupon: any;
  @Input() program: any;

  public couponValue: string;
  public invalidCoupon: boolean;

  public programId: string;

  constructor(
    private couponService: CouponService,
    private mapErrorService: MapErrorService,
    private modalService: ModalService,
  ) { }

  ngOnInit() : void {
    this.programId = this.program ? this.program : 'premium';
  }
  public openAddCouponModal() {
    this.couponValue = null;
    this.invalidCoupon = false;
    this.modalService.showModal(this.addCouponModal);

  }

  public openRemoveCouponModal() {
    this.modalService.showModal(this.removeCouponModal);
  }

  public closeAddCouponModal() {
    this.modalService.closeModal();
  }

  public closeRemoveCouponModal() {
    this.programId = this.program ? this.program : 'premium';
    this.modalService.closeModal();
  }

  public insertCoupon() {
    if (!this.couponValue) {
      this.invalidCoupon = true;
      return;
    }

    this.couponService.insertCoupon(this.couponValue).subscribe(() => {
      this.couponEmitter.emit();
      this.closeAddCouponModal();
    }, (error: HttpErrorResponse) => {
      this.invalidCoupon = true;
      if (error.error.errors[0] && error.error.errors[0].code !== '3') {
        this.mapErrorService.handleError(error);
      }
    });
  }

  public removeCoupon() {
    this.couponService.removeCoupon(this.coupon).subscribe(() => {
      this.couponEmitter.emit();
      this.closeRemoveCouponModal();
      this.coupon = null;
    }, error => {
      this.mapErrorService.handleError(error);
    });
  }

}

