<div id="{{modalId}}" #modalContainer class="modal d-block" tabindex="-1">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" (click)="close()">
          <span>&times;</span>
        </button>
        <h4 class="modal-title">{{title}}</h4>
      </div>
      <div class="modal-body">
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</div>