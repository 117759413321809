import { Injectable } from '@angular/core';

import { BehaviorSubject, from as observableFrom, Observable } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

import { PersistenceControllerService } from '@services/controllers/persistence-controller.service';
import { UserEndpointService } from '@services/endpoints/user-endpoint.service';

import { FacebookLoginProvider, GoogleLoginProvider, SocialAuthService } from 'angularx-social-login';

import { IForgotPassword, IUserEmail, IUserEmailLogin, IUserRegister } from '../../interfaces/user.interfaces';


export enum AssociationTypes {
  FACEBOOK = '0',
  MYFITNESSPAL = '1',
  BT = '2',
  FR = '3',
  GOOGLE = '4'
}

@Injectable()
export class UserControllerService {
  public loggedUser: BehaviorSubject<any>;
  private user: any;
  public USER_TOKEN = 'userToken';

  constructor(
    private userEndpoint: UserEndpointService,
    private persistence: PersistenceControllerService,
    private socialAuthService: SocialAuthService
  ) {
    if (!this.user) {
      this.user = this.persistence.get(this.USER_TOKEN, true);
    }
    this.loggedUser = new BehaviorSubject<any>(this.user);
  }

  emailLogin(login: IUserEmailLogin): Observable<object> {
    let body;
    if (login.phone) {
      body = {
        phone: login.phone,
        password: login.password,
        channel: login.channel,
        apiKey: login.apiKey
      }
    } else {
      body = {
        email: login.email,
        password: login.password,
        channel: login.channel,
        apiKey: login.apiKey
      }
    }
    return this.userEndpoint.emailLogin(body)
    .pipe(map((response) => {
      this.persistence.save(this.USER_TOKEN, response, true, !login.remember)
      this.user = response
      this.loggedUser.next(this.user);
      return response
    }))
  }

  loginWithGymCredentials(dataUser: any, isBodytech: boolean) {
    return this.userEndpoint.gymLogin({
      email: dataUser.email,
      password: dataUser.password,
      apiKey: 'supHa4ru',
      channel: 'Web'
    }, isBodytech)
    .pipe(map((response: any) => {
      this.persistence.save(this.USER_TOKEN, response, true, !dataUser.remember)
      this.user = response
      this.loggedUser.next(this.user);
      return response;
    }))
  }

  getUser() {
    return this.persistence.get(this.USER_TOKEN, true);
  }

  setPremium(premium: boolean) {
    const user = this.persistence.get(this.USER_TOKEN, true);
    user.user.premium = premium;
    this.persistence.save(this.USER_TOKEN, user, true);
    this.user = user;
    this.loggedUser.next(this.user);
  }

  register(user: IUserRegister) {
    return this.userEndpoint.register(user)
    .pipe(map((response) => {
      return response
    }))
  }

  public confirmEmailChange(token: string): Observable<void> {
    return this.userEndpoint.changeEmail(token);
  }

  forgotPassword(email: IUserEmail) {
    return this.userEndpoint.forgotPassword(email)
    .pipe(map((response) => {
      return response
    }))
  }

  changeForgottenPass(forgotPassword: IForgotPassword) {
    return this.userEndpoint.changeForgottenPassword(forgotPassword)
    .pipe(map((response) => {
      return response
    }))
  }

  check() {
    return this.userEndpoint.check()
    .pipe(map((response) => {
      return response
    }))
  }

  socialLogin(provider: string) {
    let socialPlatformProvider;

    if (provider === 'facebook') {
      socialPlatformProvider = FacebookLoginProvider.PROVIDER_ID;
    } else if (provider === 'google') {
      socialPlatformProvider = GoogleLoginProvider.PROVIDER_ID;
    }

    return observableFrom(this.socialAuthService.signIn(socialPlatformProvider))
    .pipe(
      switchMap(response => {
        return this.userEndpoint.socialLogin(
        {
            email: response.email,
            name: response.name,
            loginProvider: provider === 'facebook' ? 0 : 4,
            providerKey: response.id,
            accessToken: response.authToken,
            channel: 'Web',
            apiKey: 'supHa4ru'
        })
      }),
      tap(res => {
        this.persistence.save(this.USER_TOKEN, res, true, true);
        this.user = res;
        this.loggedUser.next(this.user);
      })
    );
  }

  loggedIn() {
    return this.user != null;
  }

  logout() {
    this.user = null;
    this.persistence.delete(this.USER_TOKEN);
    this.loggedUser.next(null);
  }

  getAssociations(associationType: AssociationTypes) {
    return this.userEndpoint.getAssociations().pipe(
      map((associations: any) => {
        return associations.associations.find(association => association.loginProvider === associationType)
      }),
    );
  }

  associateWithFacebook() {
   return observableFrom(this.socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID)).pipe(
      switchMap(response => {
        return this.userEndpoint.associateWithFacebook(
          {
            providerKey: response.id,
            accessToken: response.authToken
          }
        )
      })
    )
  }

  phoneAssociate(msisdn: string) {
    return this.userEndpoint.phoneAssociate({ msisdn })
    .pipe(map((response) => {
      return response
    }))
  }

  pincodeValidate(data: any) {
    return this.userEndpoint.pincodeValidate(data)
    .pipe(map((response) => {
      return response
    }))
  }

  sendPincode(data: any) {
    return this.userEndpoint.sendPincode(data)
    .pipe(map((response) => {
      return response
    }))
  }
}
