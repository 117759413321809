import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { EndpointsService } from '@services/endpoints.service';


@Injectable()
export class BannerEndpointService {

    constructor(
      private http: HttpClient,
      private endpointsService: EndpointsService
    ) { }

    // auth
    getBanners() {
      return this.http.get(this.endpointsService.get('banner.all'));
    }
}
