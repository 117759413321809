import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';

import { ProductsService } from '@services';
import { map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PurchaseGuard implements CanActivate {

  constructor(private productService: ProductsService, private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    const code = next.params.code;
    const type = next.params.type;
    const register = next.params.register;
    if (register === 'purchase') {
      switch (type) {
        case '1': // caso seja compra de um plano premium
          return this.productService.hasPlan().pipe(
            tap(isPremium => {
              if (isPremium) {
                this.router.navigate(['/dashboard/profile'], { queryParams: {redirect: true} })
              }
            }),
            map(isPremium => !isPremium)
          );
        case '2': // caso seja compra de um pdt
          return this.productService.hasTrainingProgram(code).pipe(
            tap(hasTrainingProgram => {
              if (hasTrainingProgram) {
                this.router.navigate(['/e-commerce/programs']);
              }
            }),
            map(hasTrainingProgram => !hasTrainingProgram)
          );
        default:
          return true;
      }
    } else if (register === 'newUser') {
      return true;
    } else {
      return false;
    }
  }
}
