import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EndpointsService } from '@services/endpoints.service';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {

  constructor(
    private endpointsService: EndpointsService,
    private http: HttpClient
  ) { }

  getConfigurationByMainKey(mainKey: string): Observable<any> {
    return this.http.get(this.endpointsService.get('configuration.getConfigurationByMainKey', [mainKey]))
  }

  sessionLog(): Observable<any> {
    return this.http.post(this.endpointsService.get('configuration.sessionLog'), null)
  }


}
