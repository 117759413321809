import { Injectable } from '@angular/core';

const COOKIE_CONSENT = 'COOKIE_CONSENT'
const COOKIE_CONSENT_EXPIRE_DAYS = 30

@Injectable()
export class CookieControllerService {
    private isConsented = false;

    constructor() {
        this.isConsented = this.getCookie(COOKIE_CONSENT) === '1';
    }

    getCookie(name: string): string {
        const cookies: Array<string> = document.cookie.split(';');
        const cookieName = `${name}=`;
        let cookie: string;

        for (let i = 0; i < cookies.length; i += 1) {
            cookie = cookies[i].replace(/^\s+/g, '');
            if (cookie.indexOf(cookieName) === 0) {
                return cookie.substring(cookieName.length, cookie.length);
            }
        }
        return '';
    }

    deleteCookie(name): void {
        this.setCookie(name, '', -1);
    }

    setCookie(name: string, value: string, expireDays: number, path = ''): void {
        const d: Date = new Date();
        d.setTime(d.getTime() + expireDays * 24 * 60 * 60 * 1000);
        const expires = `expires=${d.toUTCString()}`;
        const cpath: string = path ? `; path=${path}` : '';
        document.cookie = `${name}=${value}; ${expires}${cpath}`;
    }

    consent(isConsent: boolean, e: any): boolean {
        if (!isConsent) {
            return this.isConsented;
        } else if (isConsent) {
            this.setCookie(COOKIE_CONSENT, '1', COOKIE_CONSENT_EXPIRE_DAYS);
            this.isConsented = true;
            e.preventDefault();
        }
    }
}
