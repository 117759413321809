import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EndpointsService } from '@services/endpoints.service';
import { TrainingProgram, TrainingProgramBasicInfo } from '@models';
import { map, tap, flatMap } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TrainingProgramService {

  constructor(private http: HttpClient, private endpointService: EndpointsService) { }

  getTrainingPrograms(): Observable<TrainingProgram[]> {
    let trainingPrograms: TrainingProgram[];
    let subscribed: TrainingProgram[];
    const url = this.endpointService.get('trainingProgram.all');
    return this.http.get<{ subscribed: TrainingProgram[], training_programs: TrainingProgram[] }>(url).pipe(
      tap(response => {
        trainingPrograms = response.training_programs;
        subscribed = response.subscribed;
      }),
      flatMap(response => this.getTrainingProgramsInfo(response.training_programs
        .filter(item => item.product_code).map(item => item.product_code))),
      map((trainingProgramsInfo: TrainingProgramBasicInfo[]) => {
        const result = [];

        trainingPrograms.forEach(_program => {
          const programInfo = trainingProgramsInfo.find(_info => _info.code === _program.product_code);
          if (programInfo) {
            result.push({
              ..._program,
              name: programInfo.name,
              price: programInfo.price
            });
          }
        });

        result.push(...subscribed);

        return result;
      })
    );
  }

  getTrainingProgramsInfo(trainingProgramsCodes: string[]): Observable<TrainingProgramBasicInfo[]> {
    return this.http.post<TrainingProgram[]>(this.endpointService.get('programs.retrieve'), { trainingProgramsCodes }).pipe(
      map(response => response['trainingPrograms'])
    );
  }
}
