<div class="premium-pricing">
  <div class="container">
    <div class="row">
      <!-- TODO: Trocar preços por valores de serviço -->
      <div class="col-sm-4">
        <p class="label">Assinatura mensal</p>
        <p class="price">R$24,90/mês</p>
        <button class="btn btn-btfit">Assine agora!</button>
      </div>
      <div class="col-sm-4">
        <p class="label">Assinatura semestral</p>
        <p class="price">R$99,90/semestre</p>
        <button class="btn btn-btfit">Assine agora!</button>
      </div>
      <div class="col-sm-4">
        <p class="label">Assinatura anual</p>
        <p class="price">R$124,90/ano</p>
        <button class="btn btn-btfit">Assine agora!</button>
      </div>
    </div>
  </div>
</div>
