import { Address } from './address';

export interface Customer {
  Name: string,
  Identity: number,
  IdentityType: string,
  Email: string,
  Address: Address,
  Phone?: string
}
