export const environment = {
    production: true,
    name: 'prod',
    baseUrl: {
        appLive: 'https://services.btfit.com.br/',
        payment: 'https://services.bt.fit/',
        profile: 'https://services.bt.fit/',
        ecommerce: 'https://services.bt.fit/',
        backoffice: 'https://services.bt.fit/',
        microservice: 'https://microservices.bt.fit/'
    },
    facebookClientId: '1438771273109097',
    googleClientId: '964925867731-ff1anmli73k80o91od1gael0uj1o158h.apps.googleusercontent.com',
    recaptchaSiteKey: '6LdyybQZAAAAAB6e4Wd3qeHR4AuueUCB7CxDjDx2',
    generalAppToken: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ0b2tlbiI6IldlYiNzdXBIYTRydSMwNS8xMi8yMDIxIDEzOjQ0OjI4IFBNI1dlYiJ9.u3BDmgBABxduCXfaUWj29s8FuqbsXEyrLs0XZzbbNNU',
};
