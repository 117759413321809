export interface DayFilter {
    name: string;
    shortName: string;
    code: DaysOfTheWeek;
    selected: boolean;
}

export enum DaysOfTheWeek {
    segunda = 1,
    terca,
    quarta,
    quinta,
    sexta,
    sabado,
    domingo,
}
