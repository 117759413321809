import { Injectable } from '@angular/core';
import { Meta, MetaDefinition } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class MetaService extends Meta {

  private static currentTags: MetaTag[] = [];
  private static defaultTags: MetaDefinition[] = [];

  addTags(tags: MetaDefinition[], forceCreation?: boolean): any {
    super.addTags(tags, forceCreation);
    MetaService.currentTags.push(...this.metaTags(tags));
  }

  setTags(tags: MetaDefinition[]) {
    this.clearTags();
    this.addTags(tags);
  }

  private clearTags() {
    MetaService.currentTags.forEach(_tag => this.removeTag(_tag.selector));
    MetaService.currentTags = [];
  }

  private metaTags(defs: MetaDefinition[]): MetaTag[] {
    return defs.map(_def => this.metaTag(_def));
  }

  private metaTag(def: MetaDefinition): MetaTag {
    let selector;

    if (def.property) {
      selector = `property="${def.property}"`;
    } else if (def.itemprop) {
      selector = `itemprop="${def.itemprop}"`;
    } else if (def.name) {
      selector = `name="${def.name}"`;
    }

    return { ...def, selector: selector };
  }

  useDefault() {
    this.setTags(MetaService.defaultTags);
  }

  setDefault(tags: MetaDefinition[]) {
    MetaService.defaultTags = tags;
  }

}

interface MetaTag extends MetaDefinition {
  selector: string;
}
