<span hidden #i18nShareTitle i18n="@@shareTitle">SEJA QUEM VOCÊ QUER SER</span>
<span hidden #i18nShareDesc i18n="@@shareDescription">Com o BTFIT você treina a qualquer hora e em qualquer lugar.</span>
<span hidden #i18nShareImgLink i18n="@@shareImgLink">./assets/img/photos/facebook_share_pt.jpg</span>
<span hidden #i18nAppLanguage i18n="@@appLanguage">pt-BR</span>
<span hidden #i18nCookieConsentText i18n="@@cookieConsent">
  Este site utiliza cookies para garantir que você tenha a melhor experiência em nosso site.
</span>
<span hidden #i18nCookieConsentLink i18n="@@cookieConsentLink">
  Mais informações
</span>
<span hidden #i18nCookieConsentButton i18n="@@cookieConsentButton">
  Entendi!
</span>
<ng-progress [spinner]="false"></ng-progress>
<bf-nav [routerEvents]="routerEvents" *ngIf="!timRoute() && routerEvents"></bf-nav>
<div class="flex-content">
  <router-outlet></router-outlet>
  <bf-footer></bf-footer>
</div>
