<section id="group-classes">
  <div class="opacity-layer">
  </div>
  <div class="background" [ngStyle]="{'background': 'url(' + bannerImage + ') center center / cover no-repeat'}">
  </div>
  <div class="banner">
    <h1 i18n="@@banner1Title">Aproveite todos os recursos do BTFIT!</h1>
    <h2 i18n="@@banner1Desc">Acesse Programas de Treinamento, Aulas Coletivas e Aulas Coletivas ao Vivo com o BTFIT Premium</h2>
    <div class="btn-wrapper">
      <a id="banner-start-now" [routerLink]="route" class="btn btn-btfit btn--large" i18n="@@tryPremium">
        ASSINE AGORA E SEJA PREMIUM
      </a>
    </div>
  </div>
</section>