import { PlanType } from './plan';

export interface CustomerProductDetail {
  Coupon?: Coupon,
  CustomerProducts: Array<CustomerProduct>,
  Partners: Array<any>
}

export interface CustomerProduct {
  AnamneseAnswered: boolean,
  CancellationDateTicksMobile: number,
  DaysOfTrial: number,
  NextBillingDateTicksMobile: number,
  PlanDescription: string,
  PlanType: PlanType,
  ProductId: number,
  ProductName: string,
  PurchaseDateTicksMobile: number,
  Status: Status, // 0 -> Ativo | 3 -> Bloqueado | 4 -> Cancelado
  StoreProductCode?: string,
  StoreType: StoreType,
  TermOfContractAccept: boolean,
  Type: Type // 1 -> Premium (PTO) | 2 -> Programa de Treinamento
  active?: boolean;
}

export interface Coupon {
  Id: number,
  PartnerId: number,
  ProductId: number
}

// retorno serviço C#
export enum StoreType {
  AppleStore = 0,
  GooglePlay,
  Tim,
  Bemobi,
  Unimed,
  Ecommerce
}

// retorno serviço C#
export enum Status {
  Active = 0,
  Blocked = 3,
  Canceled = 4,
}

// retorno serviço C#
export enum Type {
  Premium = 1, // acesso premium
  PCC = 2 // programa com celebridades (aline, kefera)
}
