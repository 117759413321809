import { Component, Input, OnInit, NgZone, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { LanguageService, UserControllerService } from '@services';

@Component({
  selector: 'bf-home-menu',
  templateUrl: 'bf-home-menu.component.html',
  styleUrls: [
    './bf-home-menu.component.scss',
    './../bf-nav.component.scss', 
    './../bf-nav.component.responsive.scss'
  ]
})
export class HomeMenuComponent implements OnInit, AfterViewInit {
  @Input() user: any
  userPhoto = 'https://d2ihhdegviwl6z.cloudfront.net/static/btfit-userprofile-avatar.jpg';
  public setToPt: boolean;

  constructor(
    private router: Router,
    private userCtrl: UserControllerService,
    private languageService: LanguageService,
    private zone: NgZone) {}

  ngOnInit() : void {
    const userInfo = this.user.user.userInfo
    if (userInfo) {
      const facebook = userInfo.filter(x => x.loginProvider === '0')[0]
      if (facebook) {
        this.userPhoto = `https://graph.facebook.com/${facebook.providerKey}/picture?type=small`
      }
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.setToPt = this.languageService.isSetToPt();
    }, 300);
  }
  
  logout() {
    this.userCtrl.logout()
    this.zone.run(() => this.router.navigate(['/']))
  }

}

@Component({
  selector: 'bf-home-menu-collapsed',
  template: `
    <a
      class="pull-right language-button-collapsed"
      (click)="logout()"
      href="#"
      i18n="@@menuLeaveCollapsed">SAIR</a>
  `,
  styleUrls: ['./../bf-nav.component.scss', './../bf-nav.component.responsive.scss']
})
export class HomeMenuCollapsedComponent {

  constructor(
    private router: Router,
    private userCtrl: UserControllerService,
  ) {}

  logout() {
    this.userCtrl.logout()
    this.router.navigate(['/'])
  }


}
