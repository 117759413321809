<section class="legal-publications-section">
  <div class="legal-publications-title">
    <h1 i18n="@@legalPublications">Publicações Legais</h1>
  </div>
  <ul *ngIf="(legalPublications$ | async) as legalPublications; else loading">
    <li *ngFor="let publication of legalPublications">
      <a [href]="publication.contentFile" target="_blank" rel="noreferrer">
        {{ isLanguageSetToPt ? publication.portugueseTitle : publication.englishTitle }}
      </a>
    </li>
  </ul>
</section>

<ng-template #loading>
    <bf-loader></bf-loader>
</ng-template>