import { Component, ElementRef, HostListener, Inject, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd } from '@angular/router';

import { Observable, Subscription } from 'rxjs';

import { DataService, JQ_TOKEN, UserControllerService } from '@services';


@Component({
  selector: 'bf-nav',
  templateUrl: 'bf-nav.component.html',
  styleUrls: ['./bf-nav.component.scss', './bf-nav.component.dropdown.scss', './bf-nav.component.responsive.scss']
})
export class NavComponent implements OnInit, OnDestroy {
  @Input() routerEvents: Observable<Event>;
  public elementRef;
  public languageToggle = false;
  public opaque: boolean;
  public urlPath: string;
  public user: any;
  private subscription: Subscription;

  public defaultProduct: string = null;

  @ViewChild('i18nMenuGroupClasses') MenuGroupClasses: ElementRef;
  @ViewChild('i18nMenuPto') MenuPto: ElementRef;
  @ViewChild('i18nMenuProfile') MenuProfile: ElementRef;

  constructor(
    @Inject(JQ_TOKEN) private $: any,
    private userService: UserControllerService,
    private dataService: DataService
  ) { }

  ngOnInit() : void {
    this.userService.loggedUser.subscribe(loggedUser => this.user = loggedUser);
    document.querySelectorAll('.navbar a')[0].addEventListener('click', () => {
      document.querySelectorAll('.navbar-collapse')[0].classList.remove('in');
    });

    this.subscription = this.routerEvents.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.urlPath = (event as NavigationEnd).url;

        if (this.urlPath.search('newUser') !== -1 || (this.urlPath === '/live-classes/view-mode')) {
          document.querySelectorAll('.navbar.navbar-fixed-top').forEach((navElement) => {
            navElement.classList.add('opaque');
          })
        } else {
          document.querySelectorAll('.navbar.navbar-fixed-top').forEach((navElement) => {
            navElement.classList.remove('opaque');
          })
        }
      }
    });
    this.dataService.product.subscribe(defaultProduct => {
      this.defaultProduct = defaultProduct;
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  @HostListener('window:scroll', ['$event'])
  applyClass(event) {
    if (this.user == null) {
      const target = event.target || event.srcElement;
      if (!this.urlPath || (this.urlPath && (this.urlPath.search('newUser') === -1) && (this.urlPath !== '/live-classes/view-mode'))) {
        if (target.scrollingElement.scrollTop > 10) {
          document.querySelectorAll('.navbar.navbar-fixed-top').forEach((navElement) => {
            navElement.classList.add('opaque');
          })
        } else {
          document.querySelectorAll('.navbar.navbar-fixed-top').forEach((navElement) => {
            navElement.classList.remove('opaque');
          })
        }
      }
    }
  }

  private isPurchasePage(): boolean {
    return (!this.urlPath || (this.urlPath && this.urlPath.search('newUser') !== -1))
  }

}
