import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { EndpointsService } from '@services/endpoints.service';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  private acceptLanguage: string;

  constructor(
    private http: HttpClient,
    private endpointsService: EndpointsService,
  ) { }

  setLanguage(language: string) {
    this.acceptLanguage = language;
  }

  getLanguage(): string {
    return this.acceptLanguage;
  }

  getCurrency(): string {
    return this.acceptLanguage.includes('pt') ? 'BRL' : 'USD';
  }

  isSetToPt(): boolean {
    return this.acceptLanguage.includes('pt');
  }

  getCurrencyRatio(conversion?: string): Observable<number> {
    if (!conversion) {
      conversion = 'BRL_USD';
    }
    return this.http.get(this.endpointsService.get('currency.ratio', [conversion])).pipe(
      map((res: any) => res[conversion])
    );
  }
}
