<div class="dark-container">
  <div class="dark-container-header">
    <div class="TitilliumWeb-Bold">{{ title || 'Adicione um título ao seu container escuro :)'}}</div>
    <div class="TitilliumWeb-Bold">{{ percentualProgress }}</div>
  </div>
  <div class="meter" *ngIf="percentualProgress">
    <span [ngStyle]="{'width': percentualProgress}" style="width:30%;"><span class="progress"></span></span>
  </div>
  <div class="dark-container-content" [ngClass]="{ 'shrink': shrink }">
    <ng-content></ng-content>
  </div>
</div>