import { UserControllerService } from '@services';
import { Injectable } from '@angular/core';
import { Router,  CanActivate,  ActivatedRouteSnapshot} from '@angular/router';

@Injectable()
export class LoginGuard implements CanActivate {

  constructor(
    private userService: UserControllerService,
    private router: Router
  ) { }

  canActivate(route: ActivatedRouteSnapshot) {
    // logged user
    if (this.userService.loggedIn()) {
      this.router.navigate(['/dashboard/pdt']);
      return false;
    }
    return true;
  }
}
