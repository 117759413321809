
import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';

import { ToastrService } from 'ngx-toastr';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { IFeedbackBody, IFeedbackResponse } from 'app/shared/interfaces/feedback.interfaces';

import { ActivityEndpointService } from '../endpoints/activity-endpoint.service';


@Injectable()
export class ActivityControllerService implements Resolve<any> {

  constructor(
    private activityEndpoint: ActivityEndpointService,
    private toastr: ToastrService
  ) { }

  getActivities() {
    return this.activityEndpoint.getActivities();
  }

  feedback(feedback: IFeedbackBody): Observable<IFeedbackResponse> {
    return this.activityEndpoint.feedback(feedback);
  }

  resolve() {
    return this.getActivities().pipe(map(classes => {
      return classes;
    }), catchError((err) => { // TODO tratar erro
      this.toastr.error(err);
      return observableThrowError(err.json());
    }))
  }

}
