import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EndpointsService } from '@services/endpoints.service';

@Injectable({
  providedIn: 'root'
})
export class CorporativeService {

  constructor(
    private endpointsService: EndpointsService,
    private http: HttpClient
  ) { }

  sendForm(form: any): Observable<any> {
    return this.http.post(this.endpointsService.get('corporative.sendEmail'), form);
  }

  getPlans() {
    return this.http.get(this.endpointsService.get('corporative.getPlans'));
  }

}
