
import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';

import { forkJoin, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { ChallengesService } from './challenges.service';


@Injectable()
export class PdtService implements Resolve<any> {

  constructor(
    private challengesService: ChallengesService
  ) { }

  resolve() {
    return forkJoin([
      this.challengesService.listChallengesOrPdt('challenges'),
      this.challengesService.listChallengesOrPdt('trainingProgram'),
    ]).pipe(
      map(res => {
        return {
          challenges: res[0].featured ? [...res[0].challenges, res[0].featured ] : [...res[0].challenges],
          subscribed: res[0].subscribed.filter(sub => !sub.completed),
          completed: res[0].subscribed.filter(sub => sub.completed),

          pccs: [...res[1].training_programs],
          subscribedPccs: res[1].subscribed.filter(sub => !sub.completed),
          completedPccs: res[1].subscribed.filter(sub => sub.completed),

        }
      }),
      catchError(error => {
        return of({ response: null, error });
      })
    )
  }

}
