<div [id]="componentId" (click)="cardClicked()" class="titled-card">
  <img class="card-background" [src]="backgroundImage">
  <div *ngIf="gradient" class="fade-layer"></div>
  <div class="card-content">
    <p class="card-title">{{ title }}</p>
    <div class="card-button">
      <a id="programs-start-now" [routerLink]="[routeUrl]" class="btn btn-btfit" i18n="@@seeMore">SAIBA MAIS</a>
    </div>
  </div>
</div>
