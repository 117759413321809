import { Component, Input } from '@angular/core';

@Component({
  selector: 'bf-topic-section',
  templateUrl: 'bf-topic-section.component.html',
  styleUrls: ['bf-topic-section.component.scss']
})
export class TopicsSectionComponent {
  @Input() title: string
  @Input() topics: string[][];
}
