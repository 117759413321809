import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

import { IMessage } from '../../interfaces/tim.interfaces';
import { TimEndpointService } from '../endpoints/tim-endpoint.service';

@Injectable()
export class TimControllerService {

  private user: any
  private message: IMessage

  constructor(
    private timEndpointService: TimEndpointService,
    private toastr: ToastrService) { }

  associate(number: string) {
    return this.timEndpointService.associate(number)
  }

  storeUser(user) {
    this.user = user
  }

  getUser() {
    return this.user
  }

  setMessage(message: IMessage) {
    this.message = message
  }

  getMessage() {
    return this.message
  }


}
