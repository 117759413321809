import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { LegalPublication } from '@models/legal-publications';

import { EndpointsService } from './endpoints.service';


@Injectable({
  providedIn: 'root'
})
export class LegalPublicationsService {
  constructor(
    private http: HttpClient,
    private endpointsService: EndpointsService,
  ) { }

  getLegalPublications(): Observable<LegalPublication[]> {
    return this.http.get<{legalPublications: LegalPublication[]}>(this.endpointsService.get('legalPublication.list')).pipe(
      map((legalPublicationResponse) => {
        return legalPublicationResponse.legalPublications;
      })
    );
  }
}
