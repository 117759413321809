<div class="coupon-section">
  <a *ngIf="coupon; else noCoupon" class="btn-link" (click)="openRemoveCouponModal()" i18n="@@removeCoupon" id="btn-remove-coupon">Remover cupom</a>
  <ng-template #noCoupon>
    <a class="btn-link" (click)="openAddCouponModal()" i18n="@@insertCoupon" id="btn-add-coupon">Deseja adicionar um cupom?</a>
  </ng-template>
</div>

<ng-template #addCouponModal>
  <bf-modal (onClose)="closeAddCouponModal()" class="confirmation-modal" modalId="addCouponModal-{{programId}}" title="Adicionar cupom" i18n-title="@@insertCoupon">
    <div class="row">
      <form #couponForm="ngForm">
        <div class="form-group col-md-12">
          <input type="text" name="couponValue" id="couponValue" class="form-control" [ngClass]="{ 'ng-invalid': invalidCoupon || couponInput.invalid && (couponInput.touched || couponInput.dirty) }" required
          placeholder="Digite seu cupom" #couponInput="ngModel" i18n-placeholder="@@insertYourCoupon" (keyup)="invalidCoupon = false" [(ngModel)]="couponValue">
          <div *ngIf="invalidCoupon || couponInput.invalid && (couponInput.touched || couponInput.dirty)">
            <strong>
              <em *ngIf="invalidCoupon || couponInput.errors.required" class="text-danger" i18n="@@invalidCoupon">Cupom inválido</em>
            </strong>
          </div>
        </div>
      </form>
    </div>
    <div class="row modal-btn-row">
      <div class="col-md-6 text-center">
        <button type="button" class="btn btn-default" style="width:100%" (click)="closeAddCouponModal()" i18n="@@formBack">Voltar</button>
      </div>
      <div class="col-md-6 text-center">
        <button type="button" class="btn btn-default" style="width:100%" (click)="insertCoupon()" [disabled]="couponInput.invalid" i18n="@@formConfirm">Confirmar</button>
      </div>
    </div>
  </bf-modal>
</ng-template>

<ng-template #removeCouponModal>
  <bf-modal (onClose)="closeAddCouponModal()" class="confirmation-modal" modalId="removeCouponModal-{{programId}}" title="Remover cupom" i18n-title="@@removeCoupon">
    <div class="row">
      <div class="col-md-12">
        <p i18n="@@confirmRemoveCoupon">Deseja remover o cupom aplicado?</p>
      </div>
    </div>
    <div class="row modal-btn-row">
      <div class="col-md-6 text-center">
        <button type="button" class="btn btn-default" style="width:100%" (click)="closeRemoveCouponModal()" i18n="@@formBack">Voltar</button>
      </div>
      <div class="col-md-6 text-center">
        <button type="button" class="btn btn-default" style="width:100%" (click)="removeCoupon()" i18n="@@formRemove">Remover</button>
      </div>
    </div>
  </bf-modal>
</ng-template>
