import { TeacherType } from "@models";

export interface FavoriteGroupClassType {
    duration: number,
    liveClassId: number,
    activity: string,
    presenter: TeacherType
    formattedDate: string,
    weekday: string,
		isLive?: boolean,
}

export interface GroupClassType {
		weekdayId: number,
		weekdayName: string,
		classes: Class[],
}

export interface Class {
	duration: number,
	liveClassId: number,
	activity: string,
	presenter: TeacherType,
	formattedDate: string,
	zoomRoom: ZoomRoom,
	isFavorite: boolean,
	isLive?: boolean,
	weekdayName?: string,
}

export interface ZoomRoom {
	password: string,
	id: string,
	url: string,
}

export interface FavoriteClassResponse {
	favoriteLiveClass: {
    live_class_id: number,
    isFavorite: boolean,	
	}
}

export interface FavoriteListResponse {
	favoriteLiveClasses: FavoriteGroupClassType[]
}

export interface ZoomSignatureResponse {
	apiKey: string,
  signature: string,
}

export interface LiveGroupClassesResponse {
	liveClasses: LiveClasses[]
}

export interface LiveClasses {
	classes: Class[],
	weekdayId: number,
	weekdayName: string,
}
