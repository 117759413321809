import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { EndpointsService } from '@services/endpoints.service';
import { Observable } from 'rxjs';
import { FavoriteClassResponse, FavoriteListResponse, LiveGroupClassesResponse, ZoomSignatureResponse } from '@models';

@Injectable({
  providedIn: 'root'
})
export class LiveClassesService {

  constructor(private http: HttpClient, private endpointsService: EndpointsService) { }

  listFavoriteClasses(): Observable<FavoriteListResponse> {
    return this.http.get<FavoriteListResponse>(this.endpointsService.get('favoriteLiveClasses.list'));
  }

  listAllGroupClasses(): Observable<LiveGroupClassesResponse> {
    return this.http.get<LiveGroupClassesResponse>(this.endpointsService.get('liveclass.list'));
  }

  favoriteOrUnfavoriteLiveClass(classId: number): Observable<FavoriteClassResponse> {
    return this.http.post<FavoriteClassResponse>(this.endpointsService.get('favoriteLiveClasses.save'), {
      "live_class_id": classId
    });
  }

  getZoomSignature(classId: string): Observable<ZoomSignatureResponse> {
    return this.http.post<ZoomSignatureResponse>(this.endpointsService.get('liveclass.zoomSignature'), {
      "meetingId": classId
    });
  }

  listWithoutAuth(): Observable<any> {
    return this.http.get<any>(this.endpointsService.get('liveClasses.listWithoutAuth'));
  }

  listAvailableActivities(): Observable<any> {
    return this.http.get<any>(this.endpointsService.get('liveClasses.listActivities'));
  }
}
