import { Injectable } from '@angular/core';

import { CookieControllerService } from './cookie-controller.service';


@Injectable()
export class PersistenceControllerService {

  constructor(private cookieService: CookieControllerService) { }

  save(key: string, obj: object, encode = false, asCookie?: boolean): void {
    const item = encode ? btoa(JSON.stringify(obj)) : JSON.stringify(obj);

    // se asCookie não for informado
    if (asCookie == null) {
      // verifica se existe algum cookie ou storage com a chave atual para atualizar ele
      const storageExists = localStorage.getItem(key) != null;
      const cookieExists = this.cookieService.getCookie(key) != null;
      asCookie = cookieExists && !storageExists;
    }

    if (asCookie) {
      this.cookieService.setCookie(key, item, 5);
    } else {
      localStorage.setItem(key, item);
    }
  }

  saveText(key: string, text:  string, encode = false, asCookie = false): void {
    const item = encode ? btoa(text) : text
    if (!asCookie) {
      localStorage.setItem(key, item)
    } else {
      this.cookieService.setCookie(key, item, 5)
    }
  }

  get(key: string, encoded = false): any {
    const cookie = this.cookieService.getCookie(key)
    const item = cookie ? cookie : localStorage.getItem(key)
    if (!item) {
      return item
    }
    return encoded ? JSON.parse(atob(item)) : JSON.parse(item);
  }

  getText(key: string, encoded = false): string {
    const cookie = this.cookieService.getCookie(key)
    const item = cookie ? cookie : localStorage.getItem(key)
    if (!item) {
      return item
    }
    return encoded ? atob(item) : item
  }

  delete(key: string): void {
    this.cookieService.deleteCookie(key)
    localStorage.removeItem(key)
  }

}
