
import {throwError as observableThrowError } from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import { Resolve } from '@angular/router';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';


import { BannerEndpointService } from '../endpoints/banner-endpoint.service';

@Injectable()
export class BannerControllerService implements Resolve<any> {

  constructor(
    private bannerEndpoint: BannerEndpointService,
    private toastr: ToastrService) { }

  resolve() {
    return this.bannerEndpoint.getBanners().pipe(map(banners => {
      return banners['banners']
    }), catchError((err) => { // TODO tratar erro
      this.toastr.error(err)
      return observableThrowError(err.json())
    }))
  }

}
