
<div id="modal-content-wrapper">
    <header class="modal-header" id="modal-header">
        <h4 id="modal-title">Atenção</h4>
        <button type="button" class="close" data-dismiss="modal" (click)="closeModal(false)">
            <span>&times;</span>
          </button>
    </header>
    <section id="modal-body">
        <p>{{ reportedComment ?  'Remover esta denúncia?' : 'Denunciar este comentário?' }}</p>
    </section>
    <footer id="modal-footer">
        <div class="col-md-6 text-center">
            <button type="button" class="btn btn-default" style="width:100%" (click)="closeModal(true)" >{{ reportedComment ?  'REMOVER' : 'DENUNCIAR' }}</button>
        </div>
        <div class="col-md-6 text-center back-btn">
            <button type="button" class="btn btn-default" style="width:100%" (click)="closeModal(false)" i18n="@@formBack">VOLTAR</button>
        </div>
    </footer>
</div>
