import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'bf-classes',
  templateUrl: 'bf-classes.component.html',
  styleUrls: ['./bf-classes.component.scss']
})
export class ClassesComponent {

  @Input() items: any[]
  @Input() user: any;
  @Input() pccs: any;

  constructor(
    private router: Router
  ) { }

  getPdtDetail(item, itemId: number, type: string) {
    if ((item.premium && !this.user.user.premium && item.enable_free_access_until_day === 0)) {
      this.router.navigate(['/e-commerce/plans']);
    } else {
      this.router.navigate(['/dashboard', type, itemId]);
    }
  }
}
