import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { EndpointsService } from '@services/endpoints.service';


@Injectable()
export class TimEndpointService {

    constructor(
      private http: HttpClient,
      private endpointsService: EndpointsService
    ) { }

    // auth
    associate(number) {
      return this.http.post(this.endpointsService.get('tim.associate'), number);
    }
}
