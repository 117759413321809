import { Component, OnInit } from '@angular/core';
import { LanguageService } from '@services';

@Component({
  selector: 'bf-premium-section',
  templateUrl: 'bf-premium-section.component.html',
  styleUrls: ['./bf-premium-section.component.scss']
})
export class PremiumSectionComponent implements OnInit {
  public setToPt: boolean;

  constructor (
    private languageService: LanguageService
  ) {}

  ngOnInit() : void {
    this.setToPt = this.languageService.isSetToPt();
  }

}
