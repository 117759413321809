import { InjectionToken } from '@angular/core';

export const CLAPPR_TOKEN = new InjectionToken('clappr')
export const CLAPPR_SELECTOR = new InjectionToken('LevelSelector')

export function clapprFactory() {
  return window['Clappr'];
}

export function clapprSelectorFactory() {
  return window['LevelSelector'];
}

export const CLAPPR_PROVIDER = { provide: CLAPPR_TOKEN, useFactory: clapprFactory }
export const CLAPPRSELECTOR_PROVIDER = { provide: CLAPPR_SELECTOR, useFactory: clapprSelectorFactory }
