export interface Payment {
  OrderId: string,
  Status: number,
  Provider: string,
  Type: string,
  Amount: number,
  ServiceTaxAmount: number,
  Currency: string,
  Country: string,
  Installments: number,
  Interest: string,
  Capture: boolean,
  Authenticate: boolean,
  Recurrent: boolean,
  SoftDescriptor: string,
  CreditCard: {
    CardNumber: number,
    Holder: string,
    ExpirationDate: string,
    SecurityCode: string,
    Brand: string,
    saveCard: boolean
  }
}
