export interface TrainingProgram {
  id: number;
  product_code?: string;
  title: string;
  short_description: string;
  full_description?: string;
  total_duration?: number;
  total_videos?: number;
  total_days?: number;
  total_calories?: number;
  language?: number;
  media_url?: string;
  sharing_url?: string;
  badge_image?: string;
  cover_type?: number;
  mobile_cover_image?: string;
  tablet_cover_image?: string;
  mobile_thumbnail_image?: string;
  tablet_thumbnail_image?: string;
  subscribed?: boolean;
  completed?: false;
  enroll_start_date?: number;
  subscribers_number?: number;
  cover_video?: {
    UploadType: number;
    SizeVideoSD: number;
    UrlVideoSD: string;
    videoId: string;
    UrlVideoHD: string;
    SizeVideoHD: number;
    metadata: {
      category: number;
      description: string;
      language: number;
      cover: string;
    };
    UrlVideoStreaming: string;
  }
  bought?: boolean;
  name?: string;
  price?: number;
}

export interface TrainingProgramBasicInfo {
  code?: string;
  productCode?: string;
  id: number;
  name: string;
  price: number;
  type: string;
  status?: number;
  active?: boolean;
}

export interface TrainingProgramMock {
  id: number;
  pageId: string;
  code: string;
  title: string;
  banner: {
    imageSrc: string;
  };
  presenter: {
    name: string;
  };
  header: Header;
  priceText: string;
  about: About;
  motivational: Motivational;
  exclusiveTraining: ExclusiveTraining;
  realResults: RealResults;
  trainAnywhere: TrainAnywhere;
  madeForYou: MadeForYou;
  results: Results;
  coach: Coach;
  ebook: Ebook;
  commitment: Commitment;
  aboutBtfit: AboutBtfit;
  promotionalVideo: PromotionalVideo;
  testimonials: Array<Testimonial>;
}


export interface PromotionalVideo {
  videoUrl: string;
  videoTitle: string;
  thumbnail: {
    imageSrc: string;
  };
}

export interface Testimonial {
  image: {
    imageSrc: string;
  };
  title: string;
  subtitle: string;
}

export interface Header {
  images: string[];
  buttonText: string;
}
export interface About {
  title: string;
  description: string[];
  features: Array<AboutFeature>;
}
export interface AboutFeature {
  icon: {
    imageSrc: string;
  };
  title: string;
  description: Array<string>;
}
export interface Motivational {
  description: string[];
  buttonText: string;
}
export interface ExclusiveTraining {
  title: string;
  description: string[];
  image?: string;
  buttonText?: string;
}
export interface RealResults {
  title: string;
  image: string;
  description: string[];
  buttonText: string;
}
export interface TrainAnywhere {
  image: string;
  title: string[];
  description: string[];
  buttonText: string;
}
export interface MadeForYou {
  title: string;
  description: string[];
  buttonText: string;
}
export interface Results {
  title: string;
  description: string[];
  image: string[];
  buttonText: string;
}
export interface Coach {
  title: string;
  photo: {
    imageSrc: string;
  };
  name: string;
  description: string[];
  buttonText: string;
}

export interface Ebook {
  title: string;
  description: string[];
  buttonText: string;
  image: string;
}
export interface Commitment {
  description: string[];
  image: string;
  buttonText: string;
}
export interface AboutBtfit {
  title: string;
  description: string[];
  image: string;
}
