import { Injectable } from '@angular/core';




@Injectable()
export class AuthEndpointService {

    constructor() { }

    logout() {
      // remove user from local storage to log user out
      localStorage.removeItem('currentUser');
    }
}
