<div class="plan-button" (click)="buyPlan()">
  <div *ngIf="plan.planType == 2 && plan.anualPercentageDiscount && plan.anualPercentageDiscount > 0" class="discount">
    <p class="discount__price">{{ plan.anualPercentageDiscount }}%</p>
    <p class="discount__text">OFF</p>
  </div>
  <div class="plan-button-content">
    <!-- Tipo de plano -->
    <p *ngIf="plan.planType === 1" class="plan" i18n="@@monthlyPlan">Plano mensal</p>
    <p *ngIf="plan.planType === 2" class="plan" i18n="@@annualPlan">Plano anual</p>
    <p *ngIf="plan.planType === 4" class="plan" i18n="@@semestralPlan">Plano semestral</p>

    <!-- Parcelas -->
    <ng-container *ngIf="plan.installments > 1 && isSetToPt">
      <p class="installments">
        <span i18n="@@planButtonInstallments">Parcelado em </span>
        {{ plan.installments }}x
        <span i18n="@@planButtonInterest">s/ juros</span>
      </p>
    </ng-container>

    <!-- Preço nas primeiras cobranças -->
    <ng-container *ngIf="plan.introdutoryPercentageDiscount && plan.discountedRecurrences">
      <p *ngIf="plan.discountedRecurrences === 1; else multiple" class="introductory-discount">
        {{ plan.monthlyPrice | currency:currency }} <span i18n="@@firstInstallment">na primeira cobrança</span>
      </p>
      <ng-template #multiple>
        <p class="introductory-discount">
          {{ plan.monthlyPrice | currency:currency }} <span i18n="@@theInstallments">nas</span> {{ plan.discountedRecurrences }} <span i18n="@@startingInstallments">primeiras cobranças</span>
        </p>
      </ng-template>
    </ng-container>

    <!-- Preço mensal -->
    <p class="price">
      {{ plan.monthlyPrice | currency:currency }}/<span i18n="@@month">mês</span>
    </p>

    <!-- Preço em outra moeda -->
    <p *ngIf="alternativeCurrency && currencyRatio && currency !== alternativeCurrency" class="alternativeCurrency">
      <span i18n="@@approximately">Aproximadamente</span> {{ plan.monthlyPrice * currencyRatio | currency:alternativeCurrency }}/<span i18n="@@month">mês</span>
    </p>
  </div>
  <div class="try-now">
    <span>
      <ng-container *ngIf="plan.daysOfTrial; else subscribe" i18n="@@planButtonTryNow">Experimente agora</ng-container>
      <ng-template #subscribe i18n="@@planButtonSignNow">Assine agora</ng-template>
    </span>
  </div>
</div>