import { UserControllerService } from '@services';
import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';

@Injectable()
export class PaymentFormGuard implements CanActivate {

  constructor(
    private userService: UserControllerService,
    private router: Router
  ) { }

  canActivate(route: ActivatedRouteSnapshot) {
    if (this.userService.loggedIn() && route.params && route.params.register === 'newUser') {
      this.router.navigate(['/dashboard/pdt']);
      return false;
    }
    return true;
  }
}
