import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DateService {

  public createMonths(): Observable<Array<string>>  {
    const month: Array<string> = [];
    for (let i = 1; i <= 12; i++) {
        if (i.toString().length === 1) {
            month.push('0' + i.toString());
        } else {
            month.push(i.toString());
        }
    }
    return Observable.create(observer => {
        observer.next(month);
    });
  }

  public createYears(): Observable<Array<string>> {
    const d = new Date();
    const currentYear = d.getFullYear();
    const years: Array<string> = [];
    for (let i = currentYear; i <= currentYear + 20; i++) {
      years.push(i.toString());
    }
    return Observable.create(observer => {
        observer.next(years);
    });
  }
}
