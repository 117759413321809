import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

import { faClock, faFire } from '@fortawesome/free-solid-svg-icons';

import ConfettiGenerator from '../../../../../node_modules/confetti-js/src/confetti.js';


@Component({
  selector: 'bf-congratulations-screen',
  templateUrl: './congratulations-screen.component.html',
  styleUrls: ['./congratulations-screen.component.scss'],
  animations: [
    trigger('popOverState', [
      state('enlarge', style({
        transform: 'scale(1)',
      })),
      state('enlarge2', style({
        transform: 'scale(0)',
      })),
      transition('enlarge => enlarge2', animate('300ms')),
      transition('enlarge2 => enlarge', animate('300ms'))
    ])
  ]
})
export class CongratulationsScreenComponent implements OnInit {
  show = false;
  enlarge = false;
  icons = [
    faClock,
    faFire
  ]
  durationText = '';

  @Input() badge: string;
  @Input() calories;
  @Input() duration;
  @Input() programName;

  @Output() exitCongratulationsScreenEmitter =  new EventEmitter<any>()

  constructor(
    private router: Router,
  ) { }

  ngOnInit() : void {
    const confettiSettings = { target: 'my-canvas', max: 300, rotate: true };
    const confetti = new ConfettiGenerator(confettiSettings);
    confetti.render();
    setTimeout(() => this.getEnlarge());
    this.durationText = parseInt('' + this.duration / 60, 10) + 'h ' + this.duration % 60 + 'min';
  }


  get stateNameEnlarge() {
    return this.enlarge ? 'enlarge' : 'enlarge2'
  }

  getEnlarge() {
    this.enlarge = !this.enlarge;
  }

  exitCongratulationsScreen(exitFinishedPDT: boolean) {
    if (exitFinishedPDT) {
      this.router.navigate(['/dashboard/pdt']);
    }
    this.exitCongratulationsScreenEmitter.emit(true);
  }

}
