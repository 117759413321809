import { Component } from '@angular/core';

import { IconDefinition } from '@fortawesome/free-solid-svg-icons';


export interface FaqItem {
  icon: IconDefinition;
  question: string;
  answer: string;
  containerWidth?: string;
  i18n?: string;
}
@Component({
  selector: 'bf-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})

export class FaqComponent {
}
