import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { environment } from '@env/environment';
import { LanguageService, PersistenceControllerService, UserControllerService } from '@services';


@Injectable()
export class AppHttpInterceptor implements HttpInterceptor {


  constructor(
    private readonly languageService: LanguageService,
    private readonly persistence: PersistenceControllerService,
    private readonly router: Router,
    private readonly userService: UserControllerService,
  ) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    // Adiciona headers em requests que não sejam para API externa
    if (
      request.url.includes(environment.baseUrl.payment)      ||
      request.url.includes(environment.baseUrl.appLive)      ||
      request.url.includes(environment.baseUrl.profile)      ||
      request.url.includes(environment.baseUrl.backoffice)   ||
      request.url.includes(environment.baseUrl.payment)      ||
      this.isMicroserviceRequest(request.url)
    ) {
      const headers = {
        'X-BTFIT-Services-Version': '9',
        'Accept-Language': this.languageService.getLanguage() || 'pt-BR',
        'Time-Zone-Offset': this.calculateTimezone().toString(),
        'Content-type': 'application/json'
      };

      const { userToken, channelToken } = this.userService.getUser() ?? {};

      if (userToken) {
        headers['Authorization'] = channelToken;
        headers['X-BTLive-Authorization-User'] = userToken;
      } else if (this.persistence.getText('appToken')) {
        headers['Authorization'] = this.persistence.getText('appToken');
      }

      request = request.clone({ setHeaders: headers });
    }

    return next.handle(request).pipe(
      tap(
      (event: HttpEvent<unknown>) => {
        if (event instanceof HttpResponse) {
          // Success nothing TODO
        }
      },
      (err) => {
        if (err instanceof HttpErrorResponse) {
          /** Use a switch case instead of a conditional if there is any different behavior for other status. */
          if ([401, 403].includes(err.status) && !this.isMicroserviceRequest(request.url)) {
            this.logoutUser();
          }
        }
      },
    ));
  }

  private isMicroserviceRequest(url: string): boolean {
    return url.includes(environment.baseUrl.microservice);
  }

  private calculateTimezone(): number {
    const timezone = new Date().getTimezoneOffset();
    return timezone * 60 * 1000 * (-1);
  }

  private logoutUser(): void {
    this.userService.logout();
    this.router.navigate(['auth/login']);
  }
}
