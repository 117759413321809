<nav class="navbar navbar-fixed-top" [ngClass]="{'opaque': opaque, 'solid': user != null}">
  <div class="navbar-header">
    <div class="menu-and-icon">
      <button id="nav-toggle" type="button" class="navbar-toggle collapsed" data-toggle="collapse" data-target="#menu" aria-expanded="false">
          <span class="sr-only">Toggle navigation</span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
      </button>
      <a id="nav-logo"
        [routerLink]="['/']"
        [ngxScrollTo]="'#video-section'"
        [ngxScrollToOffset]="-86"
        [ngxScrollToEasing]="'easeInOutQuint'"
        class="navbar-brand-btfit">
        <img src="assets/img/logo.png" alt="BTFIT LOGO">
      </a>
    </div>

    <!-- Logged In -->
    <ng-container *ngIf="user != null; else loggedOut">
      <bf-home-menu-collapsed></bf-home-menu-collapsed>
    </ng-container>
    <!-- Logged Out -->
    <ng-template #loggedOut>
      <a *ngIf="defaultProduct" id="nav-start-now" [routerLink]="['/e-commerce/payment/' + defaultProduct + '/1/newUser']" 
      class="pull-right language-button-collapsed" i18n="@@planButtonSignNow">ASSINE AGORA</a>
    </ng-template>
  </div>

  <!-- Logged -->
  <ng-container *ngIf="user != null; else noUser">
    <bf-home-menu [user]="user"></bf-home-menu>
  </ng-container>
  <!-- Logged Out -->
  <ng-template #noUser>
    <bf-landing-menu [homeScreen]="urlPath === '/'"></bf-landing-menu>
  </ng-template>

</nav>
