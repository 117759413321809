<div class="container-of-circles" #containerOfCircles>
  <ng-container *ngIf="config.totalNumberOfCards > config.numberPerPage.desktop">
    <div
      *ngFor="let circle of desktopCircles"
      class="circle-of-section desktop"
      [class.selected-circle]="selectedCircle === circle"
      (click)="selectedCircle = circle; slickModal.slickGoTo(circle * config.numberPerPage.desktop)"
    ></div>
  </ng-container>

  <ng-container *ngIf="config.totalNumberOfCards > config.numberPerPage.tablet">
    <div
      *ngFor="let circle of tabletCircles"
      class="circle-of-section tablet"
      [class.selected-circle]="selectedCircle === circle"
      (click)="selectedCircle = circle; slickModal.slickGoTo(circle * config.numberPerPage.tablet)"
    ></div>
  </ng-container>

  <ng-container *ngIf="config.totalNumberOfCards > config.numberPerPage.mobile">
    <div
      *ngFor="let circle of mobileCircles"
      class="circle-of-section mobile"
      [class.selected-circle]="selectedCircle / config.numberPerPage.mobile === circle"
      (click)="selectedCircle = circle; slickModal.slickGoTo(circle * config.numberPerPage.mobile)"
    ></div>
  </ng-container>
</div>