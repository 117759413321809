import { Component, TemplateRef } from '@angular/core';

import { ModalService } from '@services/modal.service';


@Component({
  selector: 'bf-footer',
  templateUrl: 'bf-footer.component.html',
  styleUrls: ['./bf-footer.component.scss']
})

export class FooterComponent {

  constructor(
    private modalService: ModalService,
  ) {}

  showAboutModal(modal: TemplateRef<any>): void {
    this.modalService.showModal(modal);
  }

  closeAboutModal(): void {
    this.modalService.closeModal();
  }
}
