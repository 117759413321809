  <bf-modal (onClose)="closeModal()" modalId="langModal" i18n-title="@languageSelectorTitle" title="ESCOLHER IDIOMA">
    <div class="row">
      <div class="col-md-12">
        <ul class="nav nav-pills nav-stacked">
          <li role="presentation" [ngClass]="{'active': language === 'pt-BR'}" class="active">
            <a id="language-pt" class="modal-link" href="javascript:void(0)" (click)="setChosenLanguage('pt-BR')">
              <span class="item" i18n="@languagePortuguese">PORTUGUÊS</span>
              <span class="pull-right">
                <!--img src="assets/img/flag_pt.png" alt="">
                <img src="assets/img/flag_br.png" alt=""-->
              </span>
            </a>
          </li>
          <li role="presentation" [ngClass]="{'active': language === 'en-US'}">
            <a id="language-en" class="modal-link" href="javascript:void(0)" (click)="setChosenLanguage('en-US')">
              <span class="item" i18n="@languageEnglish">ENGLISH</span>
              <span class="pull-right">
                <!--img src="assets/img/flag_uk.png" alt="">
                <img src="assets/img/flag_usa.png" alt=""-->
              </span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </bf-modal>
