<div class="classes-section">
  <ng-container *ngIf="items && items.length > 0">
    <div id="class-container" class="col-sm-12 col-md-4" *ngFor="let item of items; let i = index;">
      <div class="class-type"
        [ngClass]="{
          'premium-locked': (item.premium && !user.user.premium && item.enable_free_access_until_day === 0),
          'hovered-classe': (!item.premium && !user.user.premium) || (item.premium && user.user.premium) || (item.enable_free_access_until_day > 0)
        }"
        [ngStyle]="{
          'background-image': 'url(' + item.mobile_images.cover + ')'
        }"
        (click)="getPdtDetail(item, item.id, 'challenges')">
        <div 
          class="title"
          [ngClass]="{
            'z-2': !(item.premium && !user.user.premium && item.enable_free_access_until_day === 0)
          }">
          <div class="title-intensity-container">
            <h2 id="title-responsive">{{ item.title }}</h2>
            <div>
              <img [src]="item.intensity === 0 ? 'assets/img/easy.svg' : item.intensity === 1 ? 'assets/img/medium.svg' : 'assets/img/hard.svg'">
              <div class="intensity-title">
                {{ item.intensity === 0 ? 'Iniciante' : item.intensity === 1 ? 'Moderado' : 'Avançado' }}
              </div>
            </div>
          </div>
        </div>
        <div class="bottom-bar"
          [ngClass]="{'z-2': !(item.premium && !user.user.premium && item.enable_free_access_until_day === 0)}">
          <p>{{ item.short_description }}</p>
          <div>
            <a [id]="'class-'+i" class="btn btn-btfit TitilliumWeb-SemiBold" i18n="@@startNow"
              [routerLink]="['/dashboard/challenges/', item.id]">COMECE
              AGORA</a>
          </div>
        </div>
        <a [id]="'class-'+i+'-responsive'" class="link-responsive"
          [routerLink]="['/dashboard/challenges/', item.id]"></a>
      </div>
      <div class="mask" (click)="getPdtDetail(item, item.id, 'challenges')" *ngIf="!item.premium || user.user.premium || (!user.user.premium && item.enable_free_access_until_day !== 0)"></div>
    </div>
  </ng-container>
  <ng-container *ngIf="pccs && pccs.length > 0">
    <ng-container *ngFor="let item of pccs; let i = index;">
      <div *ngIf="item.premium_availability" id="class-container" class="col-sm-12 col-md-4">
        <div class="class-type"
          [ngClass]="{'premium-locked': !user.user.premium, 'hovered-classe': user.user.premium}"
          [ngStyle]="{'background-image': 'url(' + item.thumbnail_image + ')'}"
          (click)="getPdtDetail(item, item.id, 'trainingProgram')">
          <div
            class="title"
            [ngClass]="{
              'z-2': user.user.premium
            }">
          <div class="title-intensity-container">
            <h2 id="title-responsive">{{ item.title }}</h2>
            <div>
              <img [src]="item.intensity === 0 ? 'assets/img/easy.svg' : item.intensity === 1 ? 'assets/img/medium.svg' : 'assets/img/hard.svg'">
              <div class="intensity-title">
                {{ item.intensity === 0 ? 'Iniciante' : item.intensity === 1 ? 'Moderado' : 'Avançado' }}
              </div>
            </div>
          </div>
        </div>
          <div class="bottom-bar"
          [ngClass]="{'z-2': user.user.premium}">
            <p>{{ item.short_description }}</p>
            <div>
              <a [id]="'class-'+i" class="btn btn-btfit TitilliumWeb-SemiBold" i18n="@@startNow"
                [routerLink]="['/dashboard/trainingProgram/', item.id]">COMECE AGORA</a>
            </div>
          </div>
          <a [id]="'class-'+i+'-responsive'" class="link-responsive"
            [routerLink]="['/dashboard/trainingProgram/', item.id]"></a>
        </div>
        <div class="mask" (click)="getPdtDetail(item, item.id, 'trainingProgram')" *ngIf="!item.premium || user.user.premium || (!user.user.premium && item.enable_free_access_until_day !== 0)"></div>
      </div>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="(!pccs && !items) || (pccs && items && pccs.length === 0 && items.length === 0)">
    <div class="empty-state">
      <img class="empty-state-image" src="assets/img/icons/ic-emptystate-big.svg">
      <div class="empty-state-title">Sem novos programas no momento. Aguarde novidades!</div>
    </div>
  </ng-container>
</div>