import { Directive, ElementRef, Input, HostListener } from '@angular/core';

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[navbarClose]'
})
export class CloseNavbarDirective {
    @Input() public navbar: any;

    constructor(private element: ElementRef) { }

    @HostListener('click')
    private onClick() {
        this.navbar.classList.remove('in');
    }
}
