import { MatDialogConfig } from 'ng-dialog-animation';


export const modalAnimationConfig: MatDialogConfig = {
  animation: {
    to: 'bottom',
    incomingOptions: {
      keyframes: [
        { opacity: 0, transform: 'translateY(-100px)'},
        { opacity: 1, transform: 'translateY(0)' }
      ],
      keyframeAnimationOptions: { easing: "ease-in-out", duration:400 }
    },
            outgoingOptions: {
      keyframes: [
        { opacity: 1, transform: 'translateY(0)' },
        { opacity: 0, transform: 'translateY(-100px)' }
      ],
      keyframeAnimationOptions: { easing: "ease-in-out", duration:400 }
    }
  },
  position: { top: '10%'},
};

export const APP_CONSTANTS = {
  patterns: {
    email: '^\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,8})+$',
  }
};
