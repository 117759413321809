import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { EndpointsService } from '@services/endpoints.service';

import { IForgotPassword, IUserEmail, IUserEmailLogin, IUserRegister, IUserSocialLogin } from '../../interfaces/user.interfaces';


@Injectable()
export class UserEndpointService {

    constructor(
      private http: HttpClient,
      private endpointsService: EndpointsService
    ) { }

    associateWithFacebook(associationInfo: any) {
      return this.http.post(this.endpointsService.get('user.associateWithFacebook'), JSON.stringify(associationInfo));
    }

    /** We use just the status code of this endpoint, this is why is a void Observable. */
    changeEmail(token: string): Observable<void> {
      return this.http.put<void>(this.endpointsService.get('user.changeEmail'), { token });
    }

    changeForgottenPassword(newPass: IForgotPassword) {
      return this.http.post(this.endpointsService.get('user.changeForgottenPassword'), JSON.stringify(newPass));
    }

    check() {
      return this.http.get(this.endpointsService.get('user.check'));
    }

    emailLogin(login: IUserEmailLogin) {
      return this.http.post(this.endpointsService.get('user.emailLogin'), JSON.stringify(login));
    }

    forgotPassword(email: IUserEmail) {
      return this.http.post(this.endpointsService.get('user.forgotPasswordEmail'), JSON.stringify(email));
    }

    getAssociations() {
      return this.http.get(this.endpointsService.get('user.associations'));
    }

    gymLogin(gymLoginData: any, isBodytech: boolean) {
      return this.http.post(this.endpointsService.get(isBodytech ? 'user.bodytechLogin' : 'user.formulaLogin'), JSON.stringify(gymLoginData));
    }

    register(register: IUserRegister) {
      return this.http.post(this.endpointsService.get('user.emailRegister'), JSON.stringify(register));
    }

    socialLogin(login: IUserSocialLogin) {
      return this.http.post(this.endpointsService.get('user.socialLoginRegister'), JSON.stringify(login));
    }

    phoneAssociate(msisdn: any) {
      return this.http.post(this.endpointsService.get('user.phoneAssociate'), JSON.stringify(msisdn));
    }

    pincodeValidate(data: any) {
      return this.http.post(this.endpointsService.get('user.pincodeValidate'), JSON.stringify(data));
    }

    sendPincode(data: any) {
      return this.http.post(this.endpointsService.get('user.pincodeSend'), JSON.stringify(data));
    }
}
