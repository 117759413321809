<section class="background">
  <div class="subtitulo text-center TitilliumWeb-Bold" i18n="@@commonQuestions">DÚVIDAS FREQUENTES</div>
  <div class="article text-center TitilliumWeb-Regular" i18n="@@answerYourQuestions">Tire suas dúvidas sobre o BTFIT</div>
  <div class="questions-container">
    <div class="accordion ml-auto mr-40">
      <div>
        <input type="checkbox" name="faq_accordion" id="question1" class="accordion__input">
        <label for="question1" class="accordion__label" i18n="@@faqQuestionWhoMayWorkOut">
          Quem pode treinar com o BTFIT?
        </label>
        <div class="accordion__content">
          <p i18n="@@faqAnswerWhoMayWorkOut">
            Qualquer pessoa pode usar o BTFIT desde que não
            tenha restrições físicas e/ou clínicas para a prática de exercício físico. Em caso de desconforto físico,
            preocupação ou dúvidas sobre sua saúde, consulte um médico.
          </p>
        </div>
        <div class="line"></div>
      </div>
      <div>
        <input type="checkbox" name="faq_accordion" id="question2" class="accordion__input">
        <label for="question2" class="accordion__label" i18n="@@faqQuestionMorePlatforms">
          É possível acessar o BTFIT em mais de um dispositivo?
        </label>
        <div class="accordion__content">
          <p i18n="@@faqAnswerMorePlatforms">Sim. O BTFIT está disponível para uso nas
            plataformas iOS e Android para smartphones e tablets. Além disso, você também pode fazer as Aulas Coletivas
            e os nossos Programas de Treinamento pelo nosso site.
          </p>
        </div>
        <div class="line"></div>
      </div>
      <div>
        <input type="checkbox" name="faq_accordion" id="question3" class="accordion__input">
        <label for="question3" class="accordion__label" i18n="@@faqQuestionHowSubscribe">
          Como consigo assinar o BTFIT?
        </label>
        <div class="accordion__content">
          <p i18n="@@faqAnswerHowSubscribe">As formas de pagamento aceitas são aquelas
            definidas pelas lojas virtuais App Store e Google Play e o acesso ao app e os respectivos pagamentos ocorrerão
            sempre através destas lojas virtuais.
          </p>
        </div>
        <div class="line"></div>
      </div>
    </div>
    <div class="accordion mr-auto ml-40">
      <div>
        <input type="checkbox" name="faq_accordion" id="question4" class="accordion__input">
        <label for="question4" class="accordion__label" i18n="@@faqQuestionPTO">
          O que é o Personal Trainer Online?
        </label>
        <div class="accordion__content">
          <p i18n="@@faqAnswerPTO">O Personal Trainer Online prescreve treinos personalizados
            de acordo com seu objetivo e lugar de treino. Em casa ou na academia, um algoritmo exclusivo da Bodytech sugere
            sequências de exercícios que podem ser feitos com ou sem equipamento.
          </p>
        </div>
        <div class="line"></div>
      </div>
      <div>
        <input type="checkbox" name="faq_accordion" id="question6" class="accordion__input">
        <label for="question6" class="accordion__label" i18n="@@faqQuestionPDT">
          O que são os Programas de Treinamento?
        </label>
        <div class="accordion__content">
          <p i18n="@@faqAnswerPDT">O BTFIT preparou junto com o time técnico da Bodytech
            diversas jornadas de treinamento. Separamos nossas aulas coletivas exclusivas e montamos a jornada que encaixa
            na sua rotina de vida. Temos Programas de Treinamento longos e curtos para adequar a sua realidade.
          </p>
        </div>
        <div class="line"></div>
      </div>
      <div>
        <input type="checkbox" name="faq_accordion" id="question5" class="accordion__input">
        <label for="question5" class="accordion__label" i18n="@@faqQuestionGroupClasses">
          O que são as Aulas Coletivas?
        </label>
        <div class="accordion__content">
          <p i18n="@@faqAnswerGroupClasses">
            O BTFIT oferece aulas coletivas ao vivo de Zumba, Yoga, Fit Dance, Alongamento, Localizada, Workout, Funcional Combat e Meditação.
            Além disso, tem 6 modalidades de aulas coletivas: Mat Pilates, Abdominal, 20 Min Workout e Dança, Ballet Fitness, Running e
            Yoga no seu acervo exclusivo de mais de 1.000 aulas. Através dela, você consegue manter o hábito da atividade física de qualquer lugar,
            com uma aula que você curta e professores super competentes.
            O BTFIT consegue ser uma verdadeira academia de bolso!
          </p>
        </div>
        <div class="line"></div>
      </div>
    </div>
  </div>

  <div class="button-section">
    <a i18n="@@frequentQuestions" i18n-href="@@faqLink" href="https://bodytech.my.site.com/ajudabtfit/s/contactsupport?language=pt_BR" target="_blank" rel="noreferrer"
    class="btn btn-btfit btn--large" id="heading-start-now">
      VER TODAS DÚVIDAS FREQUENTES
    </a>
  </div>
</section>
