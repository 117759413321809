
import { Injectable } from '@angular/core';

import { Subject, BehaviorSubject } from 'rxjs';

interface ScrollData {
  container: string;
  offset: number;
}
// to do - behavior subject para salvar a posição
// que o usuario esta na landing page antes de ele trocar
// para que na hora que ele clicar em voltar, volte no mesmo
@Injectable()
export class DataService {

  private dataSource = new BehaviorSubject<any>(null);
  data = this.dataSource.asObservable();

  private offsetHomePage = new Subject<ScrollData>();
  offset = this.offsetHomePage.asObservable();

  private defaultProduct = new BehaviorSubject<string>(null);
  product = this.defaultProduct.asObservable();

  constructor() { }

  updatedDataSelection(data: string) {
    this.dataSource.next(data);
  }

  updatedOffsetHomePage(data: ScrollData) {
    this.offsetHomePage.next(data);
  }

  updateDefaultProduct(product: string) {
    this.defaultProduct.next(product)
  }

}
