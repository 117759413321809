import { CommonModule } from '@angular/common';
// Modules
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { RouterModule } from '@angular/router';

import { NgDialogAnimationService } from 'ng-dialog-animation';
import { SlickCarouselModule } from 'ngx-slick-carousel';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { ChallengesService, ConfigurationService, DataService, MapErrorService, MetaService, ModalService, PdtService } from '@services';

import { CircleForCarouselComponent } from './components/circles-for-carousel/circles-for-carousel.component';
// Components
import { ClassesComponent } from './components/classes/bf-classes.component';
import { CongratulationsScreenComponent } from './components/congratulations-screen/congratulations-screen.component';
import { CouponModalComponent } from './components/coupon-modal/coupon-modal.component';
import { DarkContainerComponent } from './components/dark-container/dark-container.component';
import { FaqComponent } from './components/faq/faq.component';
import { FooterComponent } from './components/footer/bf-footer.component';
import { LegalPublicationsComponent } from './components/footer/legal-publications/legal-publications.component';
import { HomeMenuCollapsedComponent, HomeMenuComponent } from './components/nav/bf-home-menu/bf-home-menu.component';
import { LandingMenuComponent } from './components/nav/bf-landing-menu/bf-landing-menu.component';
import { NavComponent } from './components/nav/bf-nav.component';
import { LanguageModalComponent } from './components/nav/language-modal/language-modal.component';
import { NotPremiumBannerComponent } from './components/not-premium-banner/bf-not-premium-banner.component';
import { PlanButtonComponent } from './components/plan-button/plan-button.component';
import { PremiumSectionComponent } from './components/premium-section/bf-premium-section.component';
import { PricingComponent } from './components/premium-section/pricing/pricing.component';
import { StoreButtonsComponent } from './components/store-buttons/bf-store-buttons.component';
import { TabsComponent } from './components/tabs/tabs.component';
import { TopicsSectionComponent } from './components/topic-section/bf-topic-section.component';
// Directives
import { CloseNavbarDirective } from './directives/close-navbar.directive';
import { DisableControlDirective } from './directives/disable-control.directive';
import { PhoneMaskDirective } from './directives/phone-mask.directive';
// Guards
import { AuthGuard } from './guards/auth.guard';
import { HasPurchaseGuard } from './guards/hasPurchase.guard';
import { LanguageGuard } from './guards/language.guard';
import { LoginGuard } from './guards/login.guard';
import { PaymentFormGuard } from './guards/paymentForm.guard';
import './prototypes';
import { ActivityControllerService } from './services/controllers/activity-controller.service';
import { AuthControllerService } from './services/controllers/auth-controller.service';
import { BannerControllerService } from './services/controllers/banner-controller.service';
import { CookieControllerService } from './services/controllers/cookie-controller.service';
// Services
import { PersistenceControllerService } from './services/controllers/persistence-controller.service';
import { TimControllerService } from './services/controllers/tim-controller.service';
import { UserControllerService } from './services/controllers/user-controller.service';
import { ActivityEndpointService } from './services/endpoints/activity-endpoint.service';
import { AuthEndpointService } from './services/endpoints/auth-endpoint.service';
import { BannerEndpointService } from './services/endpoints/banner-endpoint.service';
import { TimEndpointService } from './services/endpoints/tim-endpoint.service';
import { UserEndpointService } from './services/endpoints/user-endpoint.service';
import { CLAPPR_PROVIDER, CLAPPRSELECTOR_PROVIDER } from './services/vendors/clappr.service';
import { COOKIEC_PROVIDER } from './services/vendors/cookie-consent.service';
import { JQUERY_PROVIDER } from './services/vendors/jQuery.service';
import { SMARTB_PROVIDER } from './services/vendors/smart-banner.service';
import { DropdownComponent } from './utils/dropdown/bf-dropdown.component';
import { LoaderComponent } from './utils/loader/loader.component';
import { MaterialModalComponent } from './utils/material-modal/material-modal.component';
import { ModalComponent } from './utils/modal/bf-modal.component';
import { TitledCardComponent } from './utils/titled-card/titled-card.component';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    ScrollToModule.forRoot(),
    SlickCarouselModule,
    FontAwesomeModule
  ],
  declarations: [
    CircleForCarouselComponent,
    ClassesComponent,
    CongratulationsScreenComponent,
    CouponModalComponent,
    DarkContainerComponent,
    DropdownComponent,
    FaqComponent,
    FooterComponent,
    HomeMenuCollapsedComponent,
    HomeMenuComponent,
    LandingMenuComponent,
    LanguageModalComponent,
    LegalPublicationsComponent,
    LoaderComponent,
    MaterialModalComponent,
    ModalComponent,
    NavComponent,
    NotPremiumBannerComponent,
    PlanButtonComponent,
    PremiumSectionComponent,
    PricingComponent,
    StoreButtonsComponent,
    TabsComponent,
    TitledCardComponent,
    TopicsSectionComponent,

    CloseNavbarDirective,
    DisableControlDirective,
    PhoneMaskDirective,
  ],
  exports: [
    CircleForCarouselComponent,
    ClassesComponent,
    CongratulationsScreenComponent,
    CouponModalComponent,
    DarkContainerComponent,
    DropdownComponent,
    FaqComponent,
    FooterComponent,
    HomeMenuCollapsedComponent,
    HomeMenuComponent,
    LandingMenuComponent,
    LoaderComponent,
    ModalComponent,
    NavComponent,
    NotPremiumBannerComponent,
    PlanButtonComponent,
    PremiumSectionComponent,
    PricingComponent,
    StoreButtonsComponent,
    TabsComponent,
    TitledCardComponent,
    TopicsSectionComponent,

    CloseNavbarDirective,
    DisableControlDirective,
    PhoneMaskDirective,
  ],
  entryComponents: [MaterialModalComponent]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        TimControllerService,
        TimEndpointService,
        AuthGuard,
        LoginGuard,
        LanguageGuard,
        HasPurchaseGuard,
        PaymentFormGuard,
        JQUERY_PROVIDER,
        CLAPPR_PROVIDER,
        CLAPPRSELECTOR_PROVIDER,
        COOKIEC_PROVIDER,
        SMARTB_PROVIDER,
        PersistenceControllerService,
        AuthControllerService,
        AuthEndpointService,
        UserControllerService,
        MatDialog,
        MetaService,
        ModalService,
        NgDialogAnimationService,
        UserEndpointService,
        CookieControllerService,
        ActivityEndpointService,
        ActivityControllerService,
        BannerControllerService,
        BannerEndpointService,
        DataService,
        PdtService,
        MapErrorService,
        ChallengesService,
        ConfigurationService
      ]
    };
  }
}
