import { Component, Input } from '@angular/core';

import { LanguageService } from '@services';

@Component({
  selector: 'bf-not-premium-banner',
  templateUrl: './bf-not-premium-banner.component.html',
  styleUrls: ['./bf-not-premium-banner.component.scss']
})
export class NotPremiumBannerComponent {
  @Input() bannerImage: string;
  @Input() route: string;

  isSetToPt = this.languageService.isSetToPt();

  constructor(
    private languageService: LanguageService
  ) { }
}

