import { Injectable } from '@angular/core';

import { Country } from '@models';

import { CountryCode, getExampleNumber, parsePhoneNumberFromString } from 'libphonenumber-js';

import examples from '../../../../node_modules/libphonenumber-js/examples.mobile.json';


@Injectable({
  providedIn: 'root'
})
export class CountryCodeService {

  getCountryDefaultByLanguage(countries: Country[], language: string) {
    return countries.find(country => {
      if (language  === 'pt-BR') {
        return country.iso2 === 'BR'
      } else {
        return country.iso2 === 'US'
      }
    });
  }


  getMaskByCountry(numberLength: number, countryCode: CountryCode, countries: Country[]) {
    const selectedCountry = countries.find(country => country.iso2 === countryCode);

    if (selectedCountry.iso2 === 'BR') {
      return { mask: '(00) 0 0000-0000||(00) 0000-0000'}
    }

    if (selectedCountry) {
      const examplePhoneNumber = getExampleNumber(selectedCountry.iso2, examples);
      const phoneWithMask = parsePhoneNumberFromString((examplePhoneNumber.number as string)).formatNational();
      let mask = ''
      const numbers = ['1', '2', '3', '4', '5', '6', '7', '8', '9'];
      for (let i = 0; i < phoneWithMask.length; i++) {
        if (numbers.find(number => phoneWithMask[i] === number)) {
          mask = mask + '0';
        } else {
          mask = mask + phoneWithMask[i];
        }
      }
      return { mask }
    }

    return { mask: '(00) 0000-000000' }
  }

  sortCountriesBySelectedLanguage(countries: Country[], language: string) {
    if (language  === 'pt-BR') {
      countries.sort((a, b) => (a.portugueseName > b.portugueseName) ? 1 : ((b.portugueseName > a.portugueseName) ? -1 : 0));
    } else {
      countries.sort((a, b) => (a.englishName > b.englishName) ? 1 : ((b.englishName > a.englishName) ? -1 : 0));
    }
    return countries;
  }

}
