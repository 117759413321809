import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { LanguageService } from './language.service';
import { ToastrService } from 'ngx-toastr';


export enum FrontEndErrorType {
  Payment = 1,

  UnavailableCoupon,
  ExpiredCoupon,
  InvalidCoupon,

  InvalidForm,
  LoginAfterPaymentWithLogin,

  FacebookAlreadyAssociated,

  CreditCardInvalidOrInactive,
}

interface Error {
  code: string;
  message: string;
}
interface HttpError {
  httpErrorCode: number;
  errors: Error[];
}

interface FrontEndErrors {
  errorCode: FrontEndErrorType;
  portugueseMessage: string;
  englishMessage: string;
}

@Injectable()
export class MapErrorService {

  errorDictionary: HttpError[] = [
    {
      httpErrorCode: 400,
      errors: [
        {
          code: '13',
          message: 'E-mail Inválido.'
        },
        {
          code: '1',
          message: ''
        }
      ]
    },
    {
      httpErrorCode: 412,
      errors: [
        {
          code: '13',
          message: 'E-mail já existe.'
        }
      ]
    }
  ];

  genericError = {
    portugueseMessage: 'Houve um erro na sua solicitação, por favor tente novamente.',
    englishMessage: 'There was an error with your request, please try again.'
  };

  connectionError = {
    portugueseMessage: 'Não foi possível conectar-se à Internet, por favor verifique sua conexão.',
    englishMessage: 'Unable to connect to the Internet, please check your connection.'
  };

  frontEndErrors: FrontEndErrors[] = [
    {
      errorCode: FrontEndErrorType.Payment,
      portugueseMessage: 'Seu cadastro foi efetuado com sucesso, porém o pagamento não pode ser efetivado. Tente novamente.',
      englishMessage: 'Your registration was successful, but the payment cannot be effective. Try again.'
    },
    {
      errorCode: FrontEndErrorType.UnavailableCoupon,
      portugueseMessage: 'Cupom indisponível no momento.',
      englishMessage: 'Coupon currently unavailable.'
    },
    {
      errorCode: FrontEndErrorType.ExpiredCoupon,
      portugueseMessage: 'Cupom expirado.',
      englishMessage: 'Expired coupon.'
    },
    {
      errorCode: FrontEndErrorType.InvalidCoupon,
      portugueseMessage: 'Cupom inválido.',
      englishMessage: 'Invalid Coupon.'
    },
    {
      errorCode: FrontEndErrorType.InvalidForm,
      portugueseMessage: 'Formulário inválido! Verifique os campos em vermelho.',
      englishMessage: 'Invalid form! Verify the fields in red.'
    },
    {
      errorCode: FrontEndErrorType.LoginAfterPaymentWithLogin,
      portugueseMessage: 'Seu cadastro foi efetuado com sucesso, porém não foi possível prosseguir com a compra. Faça login e tente novamente.',
      englishMessage: 'Your registration was successful, but it was not possible to proceed with the purchase. Log in and try again.'
    },
    {
      errorCode: FrontEndErrorType.FacebookAlreadyAssociated,
      portugueseMessage: 'Esse perfil do Facebook já está vinculado a outra conta BTFIT.',
      englishMessage: 'This Facebook profile is already linked to another BTFIT account.'
    },
    {
      errorCode: FrontEndErrorType.CreditCardInvalidOrInactive,
      portugueseMessage: 'O cartão informado não é um cartão válido ou não está ativo.',
      englishMessage: 'The Card is not valid or is inactive.'
    },
  ]

  constructor(
    private languageService: LanguageService,
    private toastr: ToastrService,
  ) { }

  private getErrorMessage(httpErrorCode: number, code: string) {
    const err = this.errorDictionary.find(res => res.httpErrorCode === httpErrorCode);
    if (err && err.errors) {
      return err.errors.find(error => error.code === code).message;
    }
    return null;
  }

  private getFrontErrorMessage(frontEndErrorCode: FrontEndErrorType): string {
    const frontEndError = this.frontEndErrors.find(error => error.errorCode === frontEndErrorCode);
    if (frontEndError) {
      return this.languageService.isSetToPt() ? frontEndError.portugueseMessage : frontEndError.englishMessage;
    }
    return this.languageService.isSetToPt() ? this.genericError.portugueseMessage : this.genericError.englishMessage;
  }

  public handleError(httpResponse: HttpErrorResponse, frontEndErrorCode: FrontEndErrorType = null) {
    let message = '';
    if (frontEndErrorCode) {
      message = this.getFrontErrorMessage(frontEndErrorCode);
    } else if (httpResponse && httpResponse.error && httpResponse.error.errors && httpResponse.error.errors.length) {
        message = httpResponse.error.errors.map(_err => _err.message || _err ).join(', ');
    } else {
      if (httpResponse.status === 0) {
        message = this.languageService.isSetToPt() ? this.connectionError.portugueseMessage : this.connectionError.englishMessage;
      } else {
        message = httpResponse.message;
      }
    }
    this.toastr.error(message);
  }
}
