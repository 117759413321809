<section>
  <table class="table blur-container">
    <thead>
      <tr>
        <th></th>
        <th><span i18n="@@BTFITFree">BTFIT Free</span></th>
        <th><span i18n="@@BTFITPremium">BTFIT Premium</span></th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td class="col-md-8 col-sm-7 transp-border">
          <span i18n="@@monitoringExercises">Acompanhamento da evolução nos exercícios e das medidas</span>
        </td>
        <td class="col-md-2 col-sm-2 btfit-border">
          <span class="feature"><i class="fa fa-times" aria-hidden="true"></i></span>
        </td>
        <td class="col-md-2 col-sm-3 btfit-border">
          <span class="feature"><i class="fa fa-check" aria-hidden="true"></i></span>
        </td>
      </tr>
      <tr>
        <td class="col-md-8 col-sm-7 transp-border">
          <span i18n="@@offlineGroupClasses">Aulas Coletivas offline</span>
        </td>
        <td class="col-md-2 col-sm-2 btfit-border">
          <span class="feature"><i class="fa fa-times" aria-hidden="true"></i></span>
        </td>
        <td class="col-md-2 col-sm-3 btfit-border">
          <span class="feature"><i class="fa fa-check" aria-hidden="true"></i></span>
        </td>
      </tr>
      <tr>
        <td class="col-md-8 col-sm-7 transp-border">
          <span i18n="@@groupClassesNames">Aulas Coletivas de Abdominal, 20 Minute Workout, Dança e Pilates</span>
        </td>
        <td class="col-md-2 col-sm-2 btfit-border">
          <span class="feature" i18n="@@limited">Limitado</span>
        </td>
        <td class="col-md-2 col-sm-3 btfit-border">
          <span class="feature" i18n="@@unlimited">Ilimitado</span>
        </td>
      </tr>
      <tr>
        <td class="col-md-8 col-sm-7 transp-border">
          <span i18n="@@paidGroupClassesNames">Aulas Coletivas de Ballet Fitness e Yoga</span>
        </td>
        <td class="col-md-2 col-sm-2 btfit-border">
          <span class="feature"><i class="fa fa-times" aria-hidden="true"></i></span>
        </td>
        <td class="col-md-2 col-sm-3 btfit-border">
          <span class="feature" i18n="@@unlimited">Ilimitado</span>
        </td>
      </tr>
      <tr>
        <td class="col-md-8 col-sm-7 transp-border">
          <span i18n="@@customWorkouts">Treinos personalizados e gerados pelo Personal Trainer Online</span>
        </td>
        <td class="col-md-2 col-sm-2 btfit-border">
          <span class="feature"><i class="fa fa-times" aria-hidden="true"></i></span>
        </td>
        <td class="col-md-2 col-sm-3 btfit-border">
          <span class="feature"><i class="fa fa-check" aria-hidden="true"></i></span>
        </td>
      </tr>
      <tr *ngIf="setToPt">
        <td class="col-md-8 col-sm-7 transp-border">
          <span i18n="@@trainingPrograms">Programas de Treinamento</span>
        </td>
        <td class="col-md-2 col-sm-2 btfit-border">
          <span class="feature"><i class="fa fa-times" aria-hidden="true"></i></span>
        </td>
        <td class="col-md-2 col-sm-3 btfit-border">
          <span class="feature"><i class="fa fa-check" aria-hidden="true"></i></span>
        </td>
      </tr>
      <tr *ngIf="setToPt">
        <td class="col-md-8 col-sm-7 transp-border">
          <span>Aulas Coletivas ao vivo</span>
        </td>
        <td class="col-md-2 col-sm-2 btfit-border">
          <span class="feature"><i class="fa fa-times" aria-hidden="true"></i></span>
        </td>
        <td class="col-md-2 col-sm-3 btfit-border">
          <span class="feature"><i class="fa fa-check" aria-hidden="true"></i></span>
        </td>
      </tr>
    </tbody>
  </table>
</section>
