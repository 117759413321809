import { Injectable } from '@angular/core';
import { EndpointsService } from '@services/endpoints.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { State, City } from '@models';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LocationService {

  constructor(
    private endpointsService: EndpointsService,
    private http: HttpClient
  ) { }

  getStates(): Observable<Array<State>> {
    return this.http.get(this.endpointsService.get('location.states'))
    .pipe(map(res => res as Array<State>));
  }

  getCities(stateId: number): Observable<Array<City>> {
    return this.http.get(this.endpointsService.get('location.cities', [stateId]))
    .pipe(map(res => res as Array<City>));
  }
}
