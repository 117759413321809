import { LanguageService } from '@services';
import { Injectable } from '@angular/core';
import { Router,  CanActivate } from '@angular/router';

@Injectable()
export class LanguageGuard implements CanActivate {

    constructor(
        private languageService: LanguageService,
        private router: Router
    ) { }

    canActivate() {
        if (!this.languageService.isSetToPt()) {
            this.router.navigate(['/']);
            return false;
        }
        return true;
    }
}
