import { UserControllerService, PurchaseService } from '@services';
import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';

@Injectable()
export class HasPurchaseGuard implements CanActivate {

  constructor(
    private userService: UserControllerService,
    private purchaseService: PurchaseService,
  ) { }

  canActivate() {
    // used logged in and has made a purchase
    return this.userService.loggedIn() && this.purchaseService.getLastPurchase() != null;
  }
}
