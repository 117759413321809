import { Component, OnInit } from '@angular/core';

import { Observable } from 'rxjs';

import { LegalPublication } from '@models/legal-publications';
import { LanguageService } from '@services/language.service';
import { LegalPublicationsService } from '@services/legal-publications.service';


@Component({
  selector: 'bf-legal-publications',
  templateUrl: 'legal-publications.component.html',
  styleUrls: ['./legal-publications.component.scss']
})

export class LegalPublicationsComponent implements OnInit {
  public legalPublications$: Observable<LegalPublication[]>;
  public isLanguageSetToPt: boolean;

  constructor(
    private languageService: LanguageService,
    private legalPublicationsService: LegalPublicationsService
  ) { }

  ngOnInit(): void {
    this.isLanguageSetToPt = this.languageService.isSetToPt();
    this.legalPublications$ = this.legalPublicationsService.getLegalPublications();
  }
}
