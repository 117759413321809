import { Attribute, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'bf-tabs',
  templateUrl: 'tabs.component.html',
  styleUrls: ['./tabs.component.scss']
})
export class TabsComponent {

  @Input() selectedTab = true;
  @Output('selectedTab') selectTabEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    @Attribute('rightTitle') public rightTitle: string,
    @Attribute('leftTitle') public leftTitle: string,
  ) { }

  selectTab(selectedRight: boolean) {
    this.selectedTab = selectedRight;
    this.selectTabEmitter.emit(this.selectedTab);
  }

}
