import { Routes } from '@angular/router';

import { AuthGuard } from '@guards';

import { ForgotPassNewComponent } from 'app/auth/pages/forgot-pass-new/bf-forgot-pass-new.component';


export const appRouter: Routes = [
  {
    path: '',
    loadChildren: () => import('app/home/home.module').then(m => m.HomeModule),
    pathMatch: 'prefix',
  },
  {
    path: 'auth',
    loadChildren: () => import('app/auth/auth.module').then(m => m.AuthModule),
    pathMatch: 'prefix',
  },
  {
    path: 'forgot',
    component: ForgotPassNewComponent,
  },
  {
    path: 'e-commerce',
    loadChildren: () => import('app/e-commerce/e-commerce.module').then(m => m.ECommerceModule),
    pathMatch: 'prefix',
  },
  {
    path: 'dashboard',
    loadChildren: () => import('app/dashboard/dashboard.module').then(m => m.DashboardModule),
    pathMatch: 'prefix',
    canActivate: [AuthGuard]
  },
  {
    path: 'live-classes',
    loadChildren: () => import('app/live-classes/live-classes.module').then(m => m.LiveClassesModule),
    pathMatch: 'prefix',
  },
  {
    path: 'timfitness',
    loadChildren: () => import('app/timfitness/tim.module').then(m => m.TimModule),
    pathMatch: 'prefix',
  },
  {
    path: 'corporativo',
    loadChildren: () => import('app/corporative/corporative.module').then(m => m.CorporativeModule),
    pathMatch: 'prefix',
  },
  {
    path: 'zoom',
    loadChildren: () => import('app/zoom/zoom.module').then(m => m.ZoomModule),
    pathMatch: 'prefix',
  },
  { path: '**', redirectTo: '/', pathMatch: 'full' }

]
