export * from './controllers/activity-controller.service'
export * from './controllers/auth-controller.service'
export * from './controllers/banner-controller.service'
export * from './controllers/cookie-controller.service'
export * from './controllers/persistence-controller.service'
export * from './controllers/tim-controller.service'
export * from './controllers/user-controller.service'
export * from './endpoints/activity-endpoint.service'
export * from './endpoints/auth-endpoint.service'
export * from './endpoints/banner-endpoint.service'
export * from './endpoints/tim-endpoint.service'
export * from './endpoints/user-endpoint.service'
export * from './vendors/clappr.service'
export * from './vendors/cookie-consent.service'
export * from './vendors/jQuery.service'
export * from './vendors/slick-carousel.service'
export * from './vendors/smart-banner.service'

export * from './challenges.service'
export * from './configuration.service'
export * from './corporative.service'
export * from './country-code.service'
export * from './coupon.service'
export * from './data.service'
export * from './date.service'
export * from './endpoints.service'
export * from './language.service'
export * from './legal-publications.service'
export * from './live-classes.service'
export * from './location.service'
export * from './map-error.service'
export * from './meta.service'
export * from './modal.service'
export * from './pdt.service'
export * from './products.service'
export * from './purchase.service'
export * from './training-program.service'