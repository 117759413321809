<div id="menu" class="navbar-collapse collapse" #navbar>
  <!-- Logged Out right side of the desktop navigation bar -->
  <ul id="language" class="nav navbar-nav navbar-right">
    <li><a *ngIf="defaultProduct" id="nav-start-now-2" [routerLink]="['/e-commerce/payment/' + defaultProduct + '/1/newUser']"
          class="scroll-link language-button" i18n="@@planButtonSignNow" style="text-transform: uppercase">ASSINE AGORA</a></li>
    <li><a id="nav-login" [routerLink]="['/auth/login']" [queryParams]="{redirectTo: redirectTo}" class="scroll-link" i18n="@@menuLogin">ENTRAR</a></li>
    <li><a id="nav-language" href="javascript:void(0)" (click)="showLangModal()" class="scroll-link" i18n="@@menuLanguage">PT</a></li>
  </ul>
  <!-- Mobile Collapsible Navigation for the Home Screen -->
  <ng-container *ngIf="homeScreen; else backButtonNav">
    <ul class="nav navbar-nav navbar-right home-links">
      <li *ngIf="isPtBR()">
        <a id="nav-programs-section"
          class="navbar-item"
          (click)="setOffset('live-classes-section', -72)"
          [navbar]="navbar"
          navbarClose
          >
          COLETIVAS AO VIVO
        </a>
      </li>
      <li *ngIf="isPtBR()">
        <a id="nav-programs-section"
          class="navbar-item"
          (click)="setOffset('programs-section', -72)"
          i18n="@@menuPrograms"
          [navbar]="navbar"
          navbarClose
          >
          PROGRAMAS DE TREINAMENTO
        </a>
      </li>
      <li>
        <a id="nav-pto"
          class="navbar-item"
          (click)="setOffset('pto-section', -72)"
          i18n="@@menuPTO"
          [navbar]="navbar"
          navbarClose
          >
          PERSONAL TRAINER ONLINE
        </a>
      </li>
      <li>
        <a id="teachers"
          class="navbar-item"
          (click)="setOffset('teachers-section', -72)"
          i18n="@@teachers"
          [navbar]="navbar"
          navbarClose
          >
          PROFESSORES
        </a>
      </li>
      <li>
        <a id="corporative"
          class="navbar-item"
          [routerLink]="['/corporativo']"
          i18n="@@menuCorporative"
          [navbar]="navbar"
          navbarClose
          >
          CORPORATIVO
        </a>
      </li>
      <li>
        <a id="blog"
          class="scroll-link links"
          href="https://blog.bt.fit/"
          target="_blank"
          rel="noreferrer"
          i18n="@@menuBlog"
          [navbar]="navbar"
          navbarClose
          >
          BLOG
        </a>
      </li>
      <li>
        <a id="faq"
          class="navbar-item"
          href="https://bodytech.my.site.com/ajudabtfit/s/"
          i18n-href="@@menuHelpLink"
          i18n="@@menuHelp"
          target="_blank"
          [navbar]="navbar"
          navbarClose
          >
          AJUDA
        </a>
      </li>
      <li>
        <a id="nav-login-2"
          [routerLink]="['/auth/login']"
          class="hide"
          i18n="@@menuLoginCollapsed"
          [navbar]="navbar"
          navbarClose
          >
          ENTRAR
        </a>
      </li>
      <li>
        <a id="nav-language-2"
          href="javascript:void(0)"
          (click)="showLangModal()"
          class="hide"
          i18n="@@menuLanguageCollapsed">
          PT
        </a>
      </li>
      <li id="divider"></li>
    </ul>
  </ng-container>

   <!-- Mobile Collapsible Navigation for Auth screens -->
   <ng-template #backButtonNav>
    <ul class="nav navbar-nav navbar-right">
      <li><a id="nav-login-3" [routerLink]="['/auth/login']" class="hide" 
        i18n="@@menuLoginCollapsed" [navbar]="navbar" navbarClose>ENTRAR</a></li>
      <li><a id="nav-language-3" href="javascript:void(0)" (click)="showLangModal()" class="hide" i18n="@@menuLanguageCollapsed">PT</a></li>
    </ul>
  </ng-template>
</div>
