import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';

import { UserControllerService } from '@services';
import { MatchValidation } from '../../../shared/validators/bf-equal-validator';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'bf-forgot-pass-new',
  templateUrl: 'bf-forgot-pass-new.component.html',
  styleUrls: ['../forgot-pass-email/bf-forgot-pass-email.component.scss', './../../bf-container.component.scss']
})
export class ForgotPassNewComponent implements OnInit {

  newPassForm: FormGroup
  password: FormControl
  confirmPassword: FormControl
  private token: string
  loading = false

  constructor(
    private activatedRoute: ActivatedRoute,
    private userController: UserControllerService,
    private toastr: ToastrService,
    private router: Router,
  ) { }

  ngOnInit() : void {
    this.password = new FormControl('', [Validators.required, Validators.pattern('(?=.*[A-Za-z])(?=.*\\d)[A-Za-z\\d]{6,12}')])
    this.confirmPassword = new FormControl('', [Validators.required])

    this.newPassForm = new FormGroup({
      password: this.password,
      confirmPassword: this.confirmPassword
    }, MatchValidation.MatchPassword)

    this.activatedRoute.queryParams.subscribe((params: Params) => {
      this.token = params['token'];
    })

  }

  send(formValues) {
    this.loading = true
    if (this.token) {
      this.userController.changeForgottenPass({
        password: formValues.password,
        token: this.token
      })
      .subscribe((res) => {
        if (res) {
          this.toastr.success('Senha alterada com sucesso! Por favor Faça o login para continuar.')
          this.loading = false
          this.router.navigate(['/auth/login'])
        }
      }, (err) => { // TODO tratar erro
        this.toastr.error('Ops! Ocorreu um problema. Por favor tente novamente.')
        this.loading = false
      })
    } else {
      this.toastr.info('Nenhum token de troca de senha foi passado.')
      this.loading = false
    }
  }

}
