import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { EndpointsService } from '@services/endpoints.service';

@Injectable({
  providedIn: 'root'
})
export class CouponService {

  constructor(private http: HttpClient, private endpointsService: EndpointsService) { }

  insertCoupon(coupon: string) {
    const body = {
      coupon_string: coupon.replace(/ /g, '').toUpperCase()
    };
    return this.http.put(this.endpointsService.get('coupon.insert'), body);
  }

  removeCoupon(couponId: number) {
    return this.http.delete(this.endpointsService.get('coupon.remove', [couponId]));
  }

  getProductByCoupon(coupon: string) {
    return this.http.get(this.endpointsService.get('coupon.getProductByCoupon', [coupon]));
  }
}
