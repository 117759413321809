import { ComponentType } from '@angular/cdk/portal';
import { Injectable, TemplateRef } from '@angular/core';
import { MatDialogRef, MatDialogState } from '@angular/material/dialog';

import { NgDialogAnimationService } from 'ng-dialog-animation';

import { modalAnimationConfig } from '../utils/app-constants';


@Injectable({
  providedIn: 'root'
})
export class ModalService {
  dialogRef: MatDialogRef<any>;

  constructor(
    private matDialog: NgDialogAnimationService,
  ) { }

  showModal(modalTemplate: ComponentType<any> | TemplateRef<any>): void {
    if (this.dialogRef && this.dialogRef.getState() === MatDialogState.OPEN) {
      this.closeModal();
    }

    this.dialogRef = this.matDialog.open(modalTemplate, modalAnimationConfig);
  }

  closeModal(): void {
    this.dialogRef.close();
  }
}
